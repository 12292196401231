import React, { useState, useContext, useEffect } from "react";
import { Jumbotron, Card, Spinner } from "react-bootstrap";
import Axios from "axios";
import { ApiContext } from "../contexts/ApiContext";
import { CorsAzureMelContext } from "../contexts/CorsAzureMelContext";
import ReportCards from "./ReportCards";
import ReportCardsLastMnth from "./ReportCardsLastMnth";

const Report = () => {
  const [loading, setLoading] = useState(true);
  const { corsData } = useContext(CorsAzureMelContext);
  const { apis } = useContext(ApiContext);
  const [lastMonthMel, setLastMonthMel] = useState([]);
  const [lastMonthSyd, setLastMonthSyd] = useState([]);
  const [fyMel, setfyMel] = useState([]);
  const [fySyd, setfySyd] = useState([]);
  const [rinexCorsCountMel, setRinexCorsCountMel] = useState([]);
  const [rinexCorsCountSyd, setRinexCorsCountSyd] = useState([]);
  const [t02CorsCountMel, setT02CorsCountMel] = useState([]);
  const [t02CorsCountSyd, setT02CorsCountSyd] = useState([]);
  const [melCompletenessHistory, setMelCompletenessHistory] = useState([]);
  const [sydCompletenessHistory, setSydCompletenessHistory] = useState([]);

  useEffect(() => {
    fetchLastMothSydData(); // eslint-disable-next-line
    fetchLastMothMelData(); // eslint-disable-next-line
    fetchFinancialYearSydData(); // eslint-disable-next-line
    fetchFinancialYearMelData(); // eslint-disable-next-line
    fetchLastMonthMelRinexCount(); // eslint-disable-next-line
    fetchLastMonthSydRinexCount(); // eslint-disable-next-line
    fetchLastMonthMelt02Count(); // eslint-disable-next-line
    fetchLastMonthSydt02Count(); // eslint-disable-next-line
    fetchMelFileCompleteHistory(); // eslint-disable-next-line
    fetchSydFileCompleteHistory(); // eslint-disable-next-line
  }, []);

  const fetchLastMothSydData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureSydAPI + "/report/lastmonth")
      .then((res) => setLastMonthSyd(res.data))
      .catch((e) => console.log(e));
  };
  const fetchLastMothMelData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureMelAPI + "/report/lastmonth")
      .then((res) => setLastMonthMel(res.data))
      .catch((e) => console.log(e));
  };

  const fetchFinancialYearSydData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureSydAPI + "/report/fy")
      .then((res) => {
        setfySyd(res.data);
      })
      .catch((e) => console.log(e));
  };
  const fetchFinancialYearMelData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureMelAPI + "/report/fy")
      .then((res) => setfyMel(res.data))
      .catch((e) => console.log(e));
  };

  const fetchLastMonthMelRinexCount = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(
      apis.gpsnetAzureMelAPI + "/report/lastmonth/rinex/corscount"
    )
      .then((res) => {
        setRinexCorsCountMel(res.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchLastMonthSydRinexCount = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(
      apis.gpsnetAzureSydAPI + "/report/lastmonth/rinex/corscount"
    )
      .then((res) => {
        setRinexCorsCountSyd(res.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchLastMonthMelt02Count = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(
      apis.gpsnetAzureMelAPI + "/report/lastmonth/t02/corscount"
    )
      .then((res) => {
        setT02CorsCountMel(res.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchLastMonthSydt02Count = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(
      apis.gpsnetAzureSydAPI + "/report/lastmonth/t02/corscount"
    )
      .then((res) => {
        setT02CorsCountSyd(res.data);
      })
      .catch((e) => console.log(e));

    setLoading(false); // eslint-disable-next-line
  };

  const fetchMelFileCompleteHistory = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureMelAPI + "/report/history")
      .then((res) => {
        setMelCompletenessHistory(res.data);
      })
      .catch((e) => console.log(e));

    setLoading(false); // eslint-disable-next-line
  };
  const fetchSydFileCompleteHistory = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsnetAzureSydAPI + "/report/history")
      .then((res) => {
        setSydCompletenessHistory(res.data);
      })
      .catch((e) => console.log(e));

    setLoading(false); // eslint-disable-next-line
  };

  return (
    <div>
      <h3>GPSnet Report</h3>
      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="primary" /> Loading...
        </div>
      ) : (
        <div>
          <h4>Last month</h4>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <div>
              {lastMonthMel.map((d) => (
                <ReportCardsLastMnth d={d} dataCentre="Melbourne" />
              ))}
            </div>
            <div>
              {lastMonthSyd.map((d) => (
                <ReportCardsLastMnth d={d} dataCentre="Sydney" />
              ))}
            </div>
            <br />
          </Jumbotron>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <div>
              {rinexCorsCountMel.map((d) => (
                <Card
                  key="melcorsRinexCompleteness"
                  style={{
                    width: "280px",
                    float: "left",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  <Card.Title>Rinex CORS stat Melbourne</Card.Title>
                  <Card.Body>
                    <h4>{d.rinexGe95Count} CORS &ge; 95% </h4>
                    <h4>{d.rinexLt95Count} CORS &lt; 95% </h4>
                    <h4>
                      {corsData.length - d.rinexGe95Count - d.rinexLt95Count < 0
                        ? 0
                        : corsData.length -
                          d.rinexGe95Count -
                          d.rinexLt95Count}{" "}
                      CORS no data
                    </h4>
                  </Card.Body>
                </Card>
              ))}
            </div>
            <div>
              {t02CorsCountMel.map((d) => (
                <Card
                  key="melcorsT02Completeness"
                  style={{
                    width: "280px",
                    float: "left",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  <Card.Title>T02 CORS stat Melbourne</Card.Title>
                  <Card.Body>
                    <h4>{d.t02Ge95Count} CORS &ge; 95% </h4>
                    <h4>{d.t02Lt95Count} CORS &lt; 95% </h4>
                    <h4>
                      {corsData.length - d.t02Ge95Count - d.t02Lt95Count < 0
                        ? 0
                        : corsData.length -
                          d.t02Ge95Count -
                          d.t02Lt95Count}{" "}
                      CORS no data
                    </h4>
                  </Card.Body>
                </Card>
              ))}
            </div>
            <div>
              {rinexCorsCountSyd.map((d) => (
                <Card
                  key="sydcorsRinexCompleteness"
                  style={{
                    width: "280px",
                    float: "left",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  <Card.Title>Rinex CORS stat Sydney</Card.Title>
                  <Card.Body>
                    <h4>{d.rinexGe95Count} CORS &ge; 95% </h4>
                    <h4>{d.rinexLt95Count} CORS &lt; 95% </h4>
                    <h4>
                      {corsData.length - d.rinexGe95Count - d.rinexLt95Count < 0
                        ? 0
                        : corsData.length -
                          d.rinexGe95Count -
                          d.rinexLt95Count}{" "}
                      CORS no data
                    </h4>
                  </Card.Body>
                </Card>
              ))}
            </div>
            <div>
              {t02CorsCountSyd.map((d) => (
                <Card
                  key="melcorsT02Completeness"
                  style={{
                    width: "280px",
                    float: "left",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  <Card.Title>T02 CORS stat Sydney</Card.Title>
                  <Card.Body>
                    <h4>{d.t02Ge95Count} CORS &ge; 95% </h4>
                    <h4>{d.t02Lt95Count} CORS &lt; 95% </h4>
                    <h4>
                      {corsData.length - d.t02Ge95Count - d.t02Lt95Count < 0
                        ? 0
                        : corsData.length -
                          d.t02Ge95Count -
                          d.t02Lt95Count}{" "}
                      CORS no data
                    </h4>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Jumbotron>{" "}
          <h4>This financial year so far</h4>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <div>
              {fyMel.map((d) => (
                <ReportCards d={d} dataCentre="Melbourne" />
              ))}
            </div>
            <div>
              {fySyd.map((d) => (
                <ReportCards d={d} dataCentre="Sydney" />
              ))}
            </div>
          </Jumbotron>
          <h4>Sites Completeness Average</h4>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            {melCompletenessHistory
              .sort((a, b) => parseInt(b.month) - parseInt(a.month))
              .map((d) => (
                <Card
                  key={d.completeness}
                  style={{
                    width: "280px",
                    float: "left",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  <Card.Title>
                    Melbourne {d.FileType} - {d.month}/{d.year}
                  </Card.Title>
                  <Card.Body>
                    <h4
                      style={{
                        color:
                          d.completeness >= 95
                            ? "green"
                            : d.completeness >= 80
                            ? "#067BC2"
                            : "red",
                      }}
                    >
                      {Math.round(d.completeness * 100) / 100} %{" "}
                    </h4>
                  </Card.Body>
                </Card>
              ))}
          </Jumbotron>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <div>
              {sydCompletenessHistory
                .sort((a, b) => parseInt(b.month) - parseInt(a.month))
                .map((d) => (
                  <Card
                    key={d.completeness}
                    style={{
                      width: "280px",
                      float: "left",
                      padding: "5px",
                      margin: "5px",
                    }}
                  >
                    <Card.Title>
                      Sydney {d.FileType} - {d.month}/{d.year}
                    </Card.Title>
                    <Card.Body>
                      <h4
                        style={{
                          color:
                            d.completeness >= 95
                              ? "green"
                              : d.completeness >= 80
                              ? "#067BC2"
                              : "red",
                        }}
                      >
                        {Math.round(d.completeness * 100) / 100} %{" "}
                      </h4>
                    </Card.Body>
                  </Card>
                ))}
            </div>
          </Jumbotron>
        </div>
      )}
    </div>
  );
};

export default Report;
