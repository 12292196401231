import React, { createContext, useContext, useState, useEffect } from "react";
import Axios from "axios";
import { ApiContext } from "./ApiContext";
export const CorsAzureSydContext = createContext();
const CorsAzureSydContextProvider = (props) => {
const { apis } = useContext(ApiContext);
const [corsData, setCorsdata] = useState([]);
const [filteredCorsData, setFilteredCorsData] = useState([]);
const [loading, setLoading] = useState(true);
const [over95RCheck, setOver95RCheck] = useState(false);
const [over95TCheck, setOver95TCheck] = useState(false);
const [under95RCheck, setUnder95RCheck] = useState(false);
const [under95TCheck, setUnder95TCheck] = useState(false);
  // Load state from localStorage on mount
useEffect(() => {
  const storedState = localStorage.getItem("corsState");
  if (storedState) {
    const parsedState = JSON.parse(storedState);
    setCorsdata(parsedState.corsData);
    setFilteredCorsData(parsedState.filteredCorsData);
    setOver95RCheck(parsedState.over95RCheck);
    setOver95TCheck(parsedState.over95TCheck);
    setUnder95RCheck(parsedState.under95RCheck);
    setUnder95TCheck(parsedState.under95TCheck);
  } 
  fetchData();
  
  setLoading(false);
}, []);
  // Store state to localStorage on update
useEffect(() => {
  localStorage.setItem(
    "corsState",
    JSON.stringify({
      corsData,
      filteredCorsData,
      over95RCheck,
      over95TCheck,
      under95RCheck,
      under95TCheck,
    })
  );
}, [
  corsData,
  filteredCorsData,
  over95RCheck,
  over95TCheck,
  under95RCheck,
  under95TCheck,
]);
  const fetchData = async () => {
  const corsdata = await Axios.get(apis.gpsnetAzureSydAPI + "/cors")
    .then((res) => {
      setCorsdata(res.data);
      setFilteredCorsData(res.data);
    })
    .catch((e) => console.log(e));
};
return (
  <CorsAzureSydContext.Provider
    value={{
      corsData,
      loading,
      filteredCorsData,
      setFilteredCorsData,
      over95RCheck,
      over95TCheck,
      under95RCheck,
      under95TCheck,
      setOver95RCheck,
      setOver95TCheck,
      setUnder95RCheck,
      setUnder95TCheck,
    }}
  >
    {props.children}
  </CorsAzureSydContext.Provider>
);
};
export default CorsAzureSydContextProvider;