import React from "react";
import { ProgressBar } from "react-bootstrap";

function DataOverall({ data, name }) {
  return (
    <div>
      <ProgressBar
        variant={
          data >= 95
            ? "success"
            : data > 80
            ? "warning"
            : data > 10
            ? "danger"
            : data > 0
            ? "light"
            : "dark"
        }
        style={{ height: "40px", fontSize: "20px" }}
        now={data}
        label={`${name} ${data}%`}
      />
      <br />
    </div>
  );
}

export default DataOverall;
