import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Spinner, Jumbotron, Card } from "react-bootstrap";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import axios from "axios";

function SiteDetails({ api, station }) {
  const [details, setdetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [position, setposition] = useState([]);
  const [siteImage, setSiteImage] = useState("");
  useEffect(() => {
    fetchData(); // eslint-disable-next-line
    fetchXMLData();
  }, []);
  const fetchData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(api)
      .then((res) => {
        setdetails(res.data);
        setposition([
          res.data.approximatePosition.coordinates[0],
          res.data.approximatePosition.coordinates[1],
        ]);

        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const fetchXMLData = async () => {
    // get the site code form url
    const site_code = window.location.href.slice(-4);
    let url =
      "https://gws.geodesy.ga.gov.au/siteLogs/search/findByFourCharacterId?id=" +
      site_code;
    const response = await axios.get(url).then((res) => {
      // Get the XML content from the response
      const xmlContent = res.data.siteLogText;
      // Extract the URL from the XML content
      const regex = /<geo:fileReference ns9:href="([^"]+)"/;
      const match = xmlContent.match(regex);
      const imageUrl = match ? match[1] : null;
      // retreive the image url
      setSiteImage(imageUrl);
    });
  };

  return (
    <div key={details.id}>
      <Jumbotron style={{ overflow: "auto" }}>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <div>
            <Card
              style={{
                width: "100%",
                float: "left",
                paddingRight: "20px",
                marginRight: "20px",
              }}
            >
              <Card.Body>
                <Card.Title>Map</Card.Title>
                <Map center={position} zoom={14}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position}>
                    <Popup>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Name:</b>
                            </td>
                            <td>{details.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Code:</b>
                            </td>
                            <td>{details.fourCharacterId}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Domes No: </b>
                            </td>
                            <td>{details.domesNumber}</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <b>Coordinate</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Lat:</b>
                            </td>
                            <td>
                              {details.approximatePosition.coordinates[0]}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Lon: </b>
                            </td>
                            <td>
                              {details.approximatePosition.coordinates[1]}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Height: </b>
                            </td>
                            <td>
                              {details.approximatePosition.coordinates[2]}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Popup>
                  </Marker>
                </Map>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "30em",
                float: "left",
                paddingRight: "20px",
                marginRight: "20px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <Card.Body>
                <Card.Title> Domes no: {details.domesNumber}</Card.Title>
                <Card.Text>
                  <span>Name: {details.name}</span>
                  <br />
                  <span>Code: {details.fourCharacterId}</span>
                  <br />
                  <span>
                    Date Installed: {details.dateInstalled.substring(0, 10)}
                  </span>
                  <br />
                  <span>Site Status: {details.siteStatus}</span>
                  <br />
                  <span>Mast: {details.monument.description}</span>
                  <br />
                  <span>Marker Desc: {details.monument.markerDescription}</span>
                  <br />
                  <span>Ant Location: {details.monument.foundation}</span>
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "15em",
                float: "left",
                paddingRight: "20px",
                marginRight: "20px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <Card.Body>
                <Card.Title>Coordinate</Card.Title>
                <Card.Text>
                  <div>
                    <span>
                      Lat: {details.approximatePosition.coordinates[0]}
                    </span>
                    <br />
                    <span>
                      Lon: {details.approximatePosition.coordinates[1]}
                    </span>
                    <br />
                    <span>
                      Height: {details.approximatePosition.coordinates[2]}
                    </span>
                    <br />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              style={{
                float: "left",
                marginRight: "20px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <Card.Body>
                <Card.Title>Image</Card.Title>
                <Card.Text>
                  <a href={siteImage} target="_blank">
                    <img src={siteImage} style={{ width: "240px" }} />
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
            <div style={{ overflow: "auto" }}></div>
          </div>
        )}
      </Jumbotron>
    </div>
  );
}

export default SiteDetails;
