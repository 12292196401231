import { Spinner, Table } from "react-bootstrap";
import { ApiContext } from "../contexts/ApiContext";
import React, { useReducer, useEffect, useContext, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import moment from 'moment';


const PowerNotification = () => {
  const { apis } = useContext(ApiContext);
  const [loading, setLoading] = useState(true);

  const [powerNotification, setPowerNotification] = useState([]);
  useEffect(() => {
    fetch(apis.s3api + "/get_UPS_battery_status")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPowerNotification(data);
        setLoading(false);
    });
  }, []);

  const columns = [
    {
      dataField: "device_name",
      text: "Device Name",
      sort: true,
      formatter: (cell, row) => cell.substring(0, 8),
      sortCaret: (order, column) => {   
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      }
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      sortCaret: (order, column) => {   
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      }
    },
    {
      dataField: "timestamp",
      text: "since",
      sort: true,
      sortCaret: (order, column) => {   
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cellContent, row) => {
        return moment.utc(cellContent).local().fromNow();
      },
    },
  ];

  return (
    <div className="container">
      <h1>Site Power Notification</h1>
      {loading ? <Spinner animation="border" /> : (<>
        {powerNotification.length === 0 ? <div>GPSnet network has no power notification at the moment</div> : 
      (
        <BootstrapTable
        keyField="id"
        data={powerNotification}
        columns={columns}
        striped
        hover
        responsive
        style={{ width: "100%", margin: "0 auto" }}
      />
      )}
      </>
      )}
    </div>
  );
};

export default PowerNotification;
