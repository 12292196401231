import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import Paper from "@material-ui/core/Paper";
import { Spinner, Tabs, Tab, Button, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const dataReducer = (filteredData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredData;
  }
};

function Reg13Check() {
  const [reg13Data, setReg13Data] = useState([]);
  const [npiData, setNpiData] = useState();
  const [npiDataLen, setNpiDataLen] = useState();
  const [gpsnetData, setGpsnetData] = useState();
  const [gpsnetDataLen, setGpsnetDataLen] = useState();
  const [loading, setLoading] = useState(true);
  const localCorsData = JSON.parse(localStorage.getItem("corsState"));
  const [d_tab, setD_tab] = useState("npi");
  const npiSite = [
    "BDLE",
    "BEEC",
    "BROC",
    "GABO",
    "MNGO",
    "MTEM",
    "NHIL",
    "MOBS",
    "PTLD",
    "STNY",
    "YNKI",
    "MILD",
  ];

  const tableStyle = {
    width: "80%",
    minWidth: "650px",
    margin: "0 auto",
  };

  const searchHandler = (keyword) => {
    const newList = reg13Data.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(keyword.toLowerCase());
    });
    assignData(newList);
  };

  const assignData = (s3apiData) => {
    let npi_tmp_data = []; // Initialize as empty array
    let gpsnet_tmp_data = []; // Initialize as empty array

    s3apiData.forEach((item) => {
      if (npiSite.includes(item.site.toUpperCase())) {
        npi_tmp_data.push(item);
      } else {
        gpsnet_tmp_data.push(item);
      }
    });
    setNpiData(npi_tmp_data);
    setNpiDataLen(npi_tmp_data.length);
    setGpsnetData(gpsnet_tmp_data);
    setGpsnetDataLen(gpsnet_tmp_data.length);
  };
  
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://s3api.vicpos.com.au/get_reg13_status_v2");
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const getReg13_exp_date = async () => {
      try {
        const response = await axios.get("https://cms.vicpos.com.au/cors?_limit=-1");
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const mergeData = (data1, data2) => {
      // for each obj in data1 and data2, if the code is the same, merge the data
      let mergedData = data1.map((obj1) => {
        const obj2 = data2.find((obj2) => obj2.code === obj1.site);
        return { ...obj1, ...obj2 };
      });
      assignData(mergedData);
      setReg13Data(mergedData);
    };

    const loadData = async () => {
      const [data1, data2] = await Promise.all([fetchData(), getReg13_exp_date()]);
      const mergedData = mergeData(data1, data2);
      setLoading(false);
    };

    loadData();
  }, []);

  const columns = [
    {
      dataField: "site",
      text: "Site",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => (
        <Button
          disabled={row.modified_date === "not available"}
          variant={
            row.modified_date === "not available" ? "warning" : "primary"
          }
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `https://ga-gnss-products-v1.s3.amazonaws.com/public/reg13/${row.file_name}`,
              "_blank"
            );
          }}
        >
          {row.site}
        </Button>
      ),
    },
    {
      dataField: "modified_date",
      text: "Last Modified",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // calculate the last_modified_date compare to today and display the result as how many days ago.
      formatter: (cell, row) => {
        const today = new Date();
        const modifiedDate = new Date(cell);
        const diffTime = Math.abs(today - modifiedDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays + " days ago";
    },
    },
    {
      dataField: "modified_date",
      text: "Modified Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      sortFunc: (a, b, order, dataField) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
    },
    {
      dataField: "reg13_exp_date",
      text: "Expiry Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      sortFunc: (a, b, order, dataField) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
      formatter: (cell, row) => {
        const expDate = new Date(cell);
        const currentDate = new Date();
        const diffTime = Math.abs(expDate - currentDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const style = () => {
          if (expDate <= currentDate) {
            return { backgroundColor: "red" };
          } else if (diffDays < 365) {
            return { backgroundColor: "orange" };
          } else {
            return {};
          }
        };
        return <span style={style()}>{cell}</span>;
      }
    },
    // Add more columns as needed
  ];

  return (
    <Container>
      <h1>Reg13 Checker</h1>
      {/* get a keyword search box below */}
      {/* <input className="search"
        type="text"
        placeholder="Search..."
        onChange={(e) => searchHandler(e.target.value)}
      /> */}
      <TextField
              aria-describedby="basic-addon1"
              label="Keyword"
              id="keyword"
              onChange={(e) => searchHandler(e.target.value)}
              margin="5px"
              variant="outlined"
              style={{ width: "100%", marginBottom: "5px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={(e) => searchHandler(e.target.value)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="primary" /> Loading...
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey={d_tab}>
            {/* GET A COUNT OF THE npiDataLen next to the title in a chips */}
            <Tab eventKey="npi" title={`NPI (${npiDataLen})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={npiData}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
            <Tab eventKey="gpsnet" title={`GPSnet (${gpsnetDataLen})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={gpsnetData}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </Container>
  );
}

export default Reg13Check;
