import React, { useContext } from "react";
import { Badge, Jumbotron, Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Moment from "moment";
import rehypeRaw from "rehype-raw";
import { ApiContext } from "../contexts/ApiContext";
import Avatar from "@material-ui/core/Avatar";

const Posts = ({ posts, loading, pageNumber }) => {
  const { apis } = useContext(ApiContext);

  if (loading) {
    return <h2>loading...</h2>;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          style={{
            backgroundColor: "#1a6985",
            height: "2em",
            width: "4em",
          }}
          variant="rounded"
        >
          Page {pageNumber}
        </Avatar>
      </div>

      <Jumbotron>
        <div>
          {posts.map((d) => (
            <div key={d._id}>
              <Card
                style={{
                  margin: "10px",
                  textAlign: "left",
                  borderRadius: ".5em",
                }}
              >
                <Card.Title
                  className="post_title"
                  style={
                    d.title.toLowerCase().includes("resolve")
                      ? { backgroundColor: "#1B5E20" }
                      : d.title.toLowerCase().includes("schedule")
                      ? { backgroundColor: "#F9A825" }
                      : d.title.toLowerCase().includes("outage")
                      ? { backgroundColor: "#B71C1C" }
                      : { backgroundColor: "#224BA1" }
                  }
                >
                  <b>{d.title}</b>

                  <br />
                </Card.Title>
                <Card.Body>
                  <b>From: </b>
                  <Badge variant="primary">
                    {Moment(d.start_date).format("llll")}
                  </Badge>
                  <b> To: </b>
                  <Badge variant="primary">
                    {Moment(d.end_date).format("llll")}
                  </Badge>
                  <ReactMarkdown
                    transformImageUri={(uri) => `${apis.gpsCMS_API}${uri}`}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {d.content}
                  </ReactMarkdown>
                  {d.update.length !== 0 ? (
                    <div>
                      <Badge variant="info">
                        {d.update.length > 1 ? "Updates" : "Update"}
                      </Badge>
                      <Badge variant="danger">{d.update.length}</Badge>
                      {d.update.map((dc) => (
                        <div key={dc._id}>
                          <Badge variant="primary">
                            {Moment(d.updatedAt).format("llll")}
                          </Badge>
                          <ReactMarkdown
                            transformImageUri={(uri) =>
                              `${apis.gpsCMS_API}${uri}`
                            }
                            rehypePlugins={[rehypeRaw]}
                          >
                            {dc.message}
                          </ReactMarkdown>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </Jumbotron>
    </div>
  );
};

export default Posts;
