import React, { useEffect, useState } from "react";
import handleFileDownload from "./S3FileDownloader"; // Import the handleFileDownload function
import AWS from "aws-sdk";
import awsConfig from "./aws-config";

const S3AccessComponent = ({ fileKey }) => {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    // Configure AWS SDK with your access keys and region
    AWS.config.update({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey,
      region: awsConfig.region,
    });

    // Create an S3 instance
    const s3 = new AWS.S3();

    // Get the file content
    s3.getObject(
      { Bucket: awsConfig.bucketName, Key: fileKey },
      (err, data) => {
        if (err) {
          console.error("Error fetching file:", err);
        } else {
          setFileContent(data.Body);
        }
      }
    );
  }, [fileKey]);

  const handleDownload = () => {
    const fileName = fileKey.substring(fileKey.lastIndexOf("/") + 1);
    handleFileDownload(fileKey, fileName, fileContent); // Use handleFileDownload function
  };

  return (
    <div>
      <button onClick={handleDownload} className="btn btn-info">
        Download File
      </button>
    </div>
  );
};

export default S3AccessComponent;
