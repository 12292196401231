import Axios from "axios";
import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Paper, Tooltip } from "@material-ui/core";
import { Spinner, Jumbotron, Form } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import { CloudDownloadIcon, FireIcon } from "@heroicons/react/solid/";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import { UserContext } from "../contexts/UserContext";

import S3AccessComponent from "./S3AccessComponent";
import { handleMultipleFileDownload } from "./MultipleFileDL";
import handleFileDownload from "./S3FileDownloader";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
// import { selection } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { v4 as uuidv4 } from "uuid";
// import { MapComponent, withLeaflet } from "react-leaflet";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    Width: 800,
  },
  paper: { Width: 900, padding: 5 },
  TableCell: { padding: "8px" },
});

const fileReducer = (filteredFiles, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredFiles;
  }
};

function App() {
  const [filteredFiles, dispatch] = useReducer(fileReducer, []);

  const { user } = useContext(UserContext);
  //set notification message
  var date = new Date();
  let test_time = new Date(date).toLocaleDateString("en-AU"); // eslint-disable-next-line

  const notifySearchResult = (message) => {
    if (message === "") {
      toast(
        <div>
          <p>
            <Avatar
              color={"secondary"}
              variant="rounded"
              style={{
                height: "20px",
                width: "90px",
                backgroundColor: "#1a6985",
                color: "white",
                fontSize: "10px",
              }}
            >
              GLACIER
            </Avatar>
            data is store in AWS S3 Glacier. Please allow 1 to 5 mins for data
            callback upon request before the data can be download. *
          </p>
          <p>
            <Avatar
              color={"secondary"}
              variant="rounded"
              style={{
                height: "20px",
                width: "90px",
                backgroundColor: "#1a6985",
                color: "white",
                fontSize: "10px",
              }}
            >
              STANDARD
            </Avatar>
            data can be download without restore.
          </p>
          Last updated: {test_time}
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          containerId: "B",
        }
      );
    } else {
      toast(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        containerId: "B",
      });
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const api = "https://s3api.vicpos.com.au";
  const [folderContent, setFolderContent] = useState([]);

  const [historyURL, setHistoryURL] = useState([{ parent: "/" }]);
  const [currentFolder, setCurrentFolder] = useState("/");

  const [fileStatus, setFileStatus] = useState({});
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [selectFile, setSelectFile] = useState({});
  const classes = useStyles();

  const [totalFileCount, setTotalFileCount] = useState(0);
  const [currentFileCount, setCurrentFileCount] = useState(0);
  const [selectedCors, setSelectedCors] = useState([]);
  const [availableCors, setAvailableCors] = useState([]);
  const [pickTime, setPickTime] = useState(
    moment().utc().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [pickDuration, setPickDuration] = useState(1);
  const [corsDropdownLoading, setCorsDropdownLoading] = useState(true);
  const [mulitpleCorsResult, setMulitpleCorsResult] = useState([]);
  const [fileKeys, setFileKeys] = useState([]);
  const [isMultiSearching, setIsMultiSearching] = useState(false);
  const [downloadableUrls, setDownloadableUrls] = useState([]);
  const roverLat = useRef(0.0);
  const roverLon = useRef(0.0);
  const roverX = useRef(0.0);
  const roverY = useRef(0.0);
  const roverZ = useRef(0.0);
  const closestCors = useRef(1);
  const [orbitDate, SetOrbitDate] = useState(
    moment().utc().format("YYYY-MM-DD")
  );
  const [typeofOrbit, setTypeofOrbit] = useState("final");
  const [orbitSearchResult, setOrbitSearchResult] = useState({});

  // const [coordinates, setCoordinates] = useState({ x: null, y: null });
  // const [coordinates, setCoordinates] = useState({ lat: null, lon: null });

  useEffect(() => {
    notifySearchResult(""); // eslint-disable-next-line
    fetchFolderContent("/"); // eslint-disable-next-line
    // populate Station list for autocomplete component
    const fetchCorsCode = async () => {
      await Axios.get("https://cms.vicpos.com.au/cors?_limit=-1").then(
        (res) => {
          let codes = [];
          res.data.forEach((element) => {
            if (
              element.status.toLowerCase() === "existing" ||
              element.status.toLowerCase() === "decomissioned"
            )
              codes.push(element.code);
          });
          setAvailableCors(codes);
          setCorsDropdownLoading(false);
        }
      );
    };
    fetchCorsCode(); // eslint-disable-next-line
  }, []);

  const sortByLastNoOrMonth = (obj) => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    obj.sort(function (a, b) {
      try {
        let aP = a.Prefix;
        let bP = b.Prefix;
        if (
          months.indexOf(aP.substring(aP.lastIndexOf(".") + 1, aP.length - 1)) >
          -1
        ) {
          return (
            months.indexOf(
              aP.substring(aP.lastIndexOf(".") + 1, aP.length - 1)
            ) -
            months.indexOf(bP.substring(bP.lastIndexOf(".") + 1, bP.length - 1))
          );
        } else {
          return (
            aP.substring(aP.lastIndexOf(".") + 1, aP.length - 1) -
            bP.substring(bP.lastIndexOf(".") + 1, bP.length - 1)
          );
        }
      } catch (error) {
        return obj;
      }
    });
  };

  const fetchFolderContent = async (prefix) => {
    setIsLoading(true);
    if (prefix !== "/") {
      // eslint-disable-next-line
      const data = await Axios.get(api + "/listFolder?parent=" + prefix).then(
        (res) => {
          sortByLastNoOrMonth(res.data);
          setFolderContent(res.data);
          dispatch({ type: "initial", payload: res.data });
          setTotalFileCount(res.data.length);
          setCurrentFileCount(res.data.length);
        }
      );
      setCurrentFolder(prefix);
      if (!historyURL.some((h) => h.parent === prefix)) {
        setHistoryURL((historyURL) => [...historyURL, { parent: prefix }]);
      }
    } else {
      // eslint-disable-next-line
      const data = await Axios.get(api + "/listFolder").then((res) => {
        setFolderContent(res.data);
        setCurrentFolder("/");
        dispatch({ type: "initial", payload: res.data });
        setTotalFileCount(res.data.length);
        setCurrentFileCount(res.data.length);
        if (!historyURL.some((h) => h.parent === "/")) {
          setHistoryURL((historyURL) => [...historyURL, { parent: "/" }]);
        }
      });
    }
    setIsLoading(false);
  };

  const [s3DialogOpen, setS3DialogOpen] = useState(false);

  const handleDialogOpen = (s3data) => {
    setS3DialogOpen(true);
    fetchFileInfo(s3data.Key);
    setSelectFile(s3data);
  };
  const handleDialogClose = () => {
    setS3DialogOpen(false);
  };

  const fetchFileInfo = async (key) => {
    setIsDialogLoading(true); // eslint-disable-next-line
    var data = await Axios.get(api + "/isAvailableForDownload?key=" + key).then(
      (res) => {
        setFileStatus(res.data);
      }
    );
    setIsDialogLoading(false);
  };

  const handleRecall = async (key) => {
    setIsDialogLoading(true); // eslint-disable-next-line
    var data = await Axios.post(api + "/RestoreByKey?key=" + key).then((res) =>
      setFileStatus(res.data)
    );
    setIsDialogLoading(false);
  };

  const handleFileRecall = async (key) => {
    let keys = fileKeys;
    // eslint-disable-next-line
    var data = await Axios.post(api + "/RestoreByKey?key=" + key).then(
      (res) => {
        notifySearchResult(key + ": " + res.data.response);
        keys = keys.filter((k) => k !== key);
        setFileKeys(keys);
      }
    );
  };

  const handleMultipleFilesRecall = (fileKeys) => {
    fileKeys.forEach((key) => {
      handleFileRecall(key);
    });
  };

  const resetHandler = () => {
    dispatch({ type: "initial", payload: folderContent });
    searchTerm.current.value = "";
    setCurrentFileCount(totalFileCount);
  };

  const searchTerm = useRef("something");

  const searchHandler = (st) => {
    const newList = folderContent.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    setCurrentFileCount(newList.length);
    dispatch({ type: "keywords", payload: newList });
  };

  //

  const getSubString = (fileKey) => {
    const fileName = fileKey.substring(
      fileKey.lastIndexOf("/") + 1,
      fileKey.length
    );
    return fileName;
  };

  const OpenGaCheckURL = (fileKey) => {
    window.open(
      "https://data.gnss.ga.gov.au/api/rinexFiles/" +
        getSubString(fileKey) +
        "?metadataStatus=invalid",
      "_blank"
    );
  };

  const handleMultipleCorsSearch = async () => {
    let query = "?";
    setFileKeys([]);
    setDownloadableUrls([]);
    selectedCors.forEach(
      (i) => (query = query + "stationCodes=" + i.toLowerCase() + "&")
    );
    query = query + "startDate=" + pickTime + "&duration=" + pickDuration;
    // eslint-disable-next-line
    var data = await Axios.get(
      "https://s3api.vicpos.com.au/getMultipleCorsT02Files" + query
    ).then((res) => {
      setMulitpleCorsResult(res.data);
      var keys = [];
      var urls = [];
      res.data.forEach((element) => {
        if (
          element.storageclass.includes("GLACIER") &&
          element.glacierinfo.includes("in glacier")
        ) {
          keys.push(element.key);
          setFileKeys(keys);
        } else if (
          element.glacierinfo.toLowerCase().includes("ongoing request false") ||
          element.storageclass.toLowerCase().includes("standard")
        ) {
          let newUrl = element.url.replace(
            "https://gpsnet-luv-gnss-data.s3.amazonaws.com/",
            ""
          );
          urls.push(newUrl);
        }
      });
      setDownloadableUrls(urls);
    });
    setIsMultiSearching(false);
  };

  const downloadAllFiles = (urls) => {
    handleMultipleFileDownload(urls);
  };

  const duration = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 18, 20, 21,
    22, 23, 24,
  ];

  const handleXYZCORSSearch = async () => {
    if (roverX.current.value === "0" && roverY.current.value === "0") {
      alert("X and Y can not be 0 at the same time");
    } else {
      if (closestCors.current.value < 1) {
        alert("No. of Cors cannot be lower that 1");
      } else {
        let url =
          "/getClosestCorsListByXYZ?x=" +
          roverX.current.value +
          "&y=" +
          roverY.current.value +
          "&z=" +
          roverZ.current.value +
          "&corsNo=" +
          closestCors.current.value;
        await Axios.get("https://s3api.vicpos.com.au/" + url).then((res) => {
          roverLat.current.value = res.data.data.rover.lat;
          roverLon.current.value = res.data.data.rover.lon;
          setSelectedCors(res.data.data.cors);
          document.getElementById("cors-list").focus();
        });
      }
    }
  };

  const handleLatLonSearch = async () => {
    if (closestCors.current.value < 1) {
      alert("No. of Cors cannot be lower that 1");
    } else {
      let url =
        "/getClosestCorsListByLatLon?lat=" +
        roverLat.current.value +
        "&lon=" +
        roverLon.current.value +
        "&corsNo=" +
        closestCors.current.value;
      await Axios.get("https://s3api.vicpos.com.au/" + url).then((res) => {
        setSelectedCors(res.data.data.cors);
        document.getElementById("cors-list").focus();
      });
    }
  };

  const handleOrbitSearch = async () => {
    let url =
      "https://s3api.vicpos.com.au/getMgexOrbitDownloader?typeofOrb=" +
      typeofOrbit +
      "&targetDate=" +
      orbitDate;
    await Axios.get(url).then((res) => setOrbitSearchResult(res.data));
  };

  const downloadOrbitFile = (url) => {
    window.open(url);
  };

  const getCurrentCoords = () => {
    console.log("button click");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          roverLat.current.value = position.coords.latitude;
          roverLon.current.value = position.coords.longitude;
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <br />

      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position="bottom-center"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ textAlign: "left", minWidth: "100%" }}
      />
      <h1>GNSS Data Repository</h1>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>MGEX Orbit Search</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ minWidth: "100%" }}>
              <div>
                <Paper
                  elevation={6}
                  style={{
                    margin: "10px",
                    padding: "20px",
                  }}
                >
                  <TextField
                    id="date-picker"
                    label="Date Time (UTC)"
                    type="date"
                    format="yyyy-mm-dd"
                    defaultValue={orbitDate}
                    onChange={(e) => SetOrbitDate(e.target.value)}
                    style={{ width: "200px" }}
                  />

                  <TextField
                    value={typeofOrbit}
                    onChange={(e) => setTypeofOrbit(e.target.value)}
                    select // tell TextField to render select
                    label="Type of Orbit File"
                    style={{
                      minWidth: "250px",
                      height: "20px",
                      paddingLeft: "10px",
                    }}
                  >
                    <MenuItem key={uuidv4()} value="final">
                      final
                    </MenuItem>
                    <MenuItem key={uuidv4()} value="rapid">
                      rapid
                    </MenuItem>
                  </TextField>
                  <br />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleOrbitSearch()}
                    style={{ margin: "10px" }}
                  >
                    Search Orbit File
                  </Button>

                  <br />
                  {orbitSearchResult.message === undefined ? (
                    <div></div>
                  ) : (
                    <div style={{ margin: "10px" }}>
                      <div style={{ textAlign: "left" }}>
                        {orbitSearchResult.message}
                      </div>

                      <div>
                        <Chip
                          style={{
                            backgroundColor: "#154c79",
                            color: "#ffffff",
                          }}
                          label="Download"
                          onClick={() =>
                            downloadOrbitFile(orbitSearchResult.url)
                          }
                        />
                      </div>
                    </div>
                  )}
                </Paper>

                <div
                  style={{
                    textAlign: "left",
                    padding: "20px",
                    color: "#717b7a",
                  }}
                >
                  {" "}
                  <p>
                    Please note the data for orbit is hosted in NASA repository,
                    GPSnet is not responsible for downloading the data neither
                    have not control of it. You should have your own log in
                    profile registered with NASA data repository to access the
                    data.{" "}
                  </p>
                  <p>
                    <sup>
                      * final orbit file will take NASA approximately 10
                      business days to process before it is available for
                      download.
                    </sup>
                  </p>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Advance File Search (T02)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ minWidth: "100%" }}>
              <TextField
                id="roverX"
                inputRef={roverX}
                type="number"
                defaultValue={0}
                label="X"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <TextField
                id="roverY"
                inputRef={roverY}
                type="number"
                defaultValue={0}
                label="Y"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <TextField
                id="roverZ"
                inputRef={roverZ}
                type="number"
                defaultValue={0}
                label="Z"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <br />
              <Button
                color="secondary"
                variant="contained"
                disabled={corsDropdownLoading}
                onClick={() => handleXYZCORSSearch()}
              >
                Convert Lat Lon
              </Button>
              <br />
              <TextField
                id="roverLat"
                inputRef={roverLat}
                type="number"
                defaultValue={0}
                label="Lat"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <TextField
                id="roverLon"
                inputRef={roverLon}
                type="number"
                defaultValue={0}
                label="Lon"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <TextField
                id="closestCors"
                inputRef={closestCors}
                type="number"
                defaultValue={1}
                label="No. of Cors"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
              <br />
              <Button
                color="primary"
                variant="contained"
                disabled={corsDropdownLoading}
                onClick={() => {
                  handleLatLonSearch();
                }}
              >
                get nearest CORS
              </Button>{" "}
              <span> </span>
              <Button
                color="primary"
                variant="contained"
                disabled={corsDropdownLoading}
                onClick={() => {
                  getCurrentCoords();
                }}
              >
                get current coordinate
              </Button>
              <br />
              {corsDropdownLoading ? (
                <Spinner animation="border" />
              ) : (
                <Autocomplete
                  multiple
                  id="cors-list"
                  options={availableCors}
                  className="w-100 p-3"
                  value={selectedCors}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Station Code(s)"
                      placeholder="CORS"
                      size="medium"
                    />
                  )}
                  style={{ Width: "100%", scrollPadding: "10px" }}
                  onChange={(e, newValue) => {
                    setSelectedCors(newValue);
                  }}
                />
              )}
              <br />
              <TextField
                id="date-picker"
                label="Start Date Time (UTC)"
                type="datetime-local"
                defaultValue={pickTime}
                onChange={(e) => setPickTime(e.target.value)}
              />
              <TextField
                value={pickDuration}
                onChange={(e) => setPickDuration(e.target.value)}
                select // tell TextField to render select
                label="Duration"
                style={{
                  minWidth: "250px",
                  height: "20px",
                  paddingLeft: "10px",
                }}
              >
                {duration.map((data) => (
                  <MenuItem key={uuidv4()} value={data}>
                    {" "}
                    {data} hours
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <div style={{ marginTop: "20px" }}>
                <Button
                  disabled={selectedCors.length === 0}
                  onClick={() => {
                    handleMultipleCorsSearch();
                    setIsMultiSearching(true);
                  }}
                  color="primary"
                  variant="outlined"
                  style={{ margin: "10px" }}
                >
                  Search / refresh ({mulitpleCorsResult.length})
                </Button>
                {fileKeys.length > 0 && (
                  <Button
                    variant="contained"
                    color="success"
                    style={{
                      margin: "10px",
                      backgroundColor: "#154c79",
                      color: "white",
                    }}
                    onClick={() => {
                      handleMultipleFilesRecall(fileKeys);
                    }}
                  >
                    <FireIcon style={{ height: "15px" }} />
                    Recall all glacier files ({fileKeys.length})
                  </Button>
                )}
                {downloadableUrls.length > 0 && (
                  <Button
                    onClick={() => downloadAllFiles(downloadableUrls)}
                    style={{ backgroundColor: "#e28743", color: "white" }}
                  >
                    <CloudDownloadIcon style={{ height: "15px" }} />
                    Download all ({downloadableUrls.length})
                  </Button>
                )}
              </div>
              <hr />
              <div>
                {isMultiSearching ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Jumbotron
                    style={{
                      overflow: "auto",
                      padding: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {mulitpleCorsResult.map((result) => (
                      <div>
                        <Tooltip
                          title={result.key + " - " + result.glacierinfo}
                          placement="top"
                        >
                          <Chip
                            onClick={() => {
                              if (
                                result.storageclass
                                  .toLowerCase()
                                  .includes("standard") ||
                                result.glacierinfo.includes("expiry date")
                              ) {
                                // window.open(result.url, "_blank");
                                // wip
                                // downloand the file back on url
                                handleFileDownload(
                                  result.url.replace(
                                    "https://gpsnet-luv-gnss-data.s3.amazonaws.com/",
                                    ""
                                  )
                                );
                                // end of wip
                              } else {
                                handleFileRecall(result.key);
                              }
                            }}
                            label={getSubString(result.key)}
                            avatar={
                              <Avatar
                                variant="rounded"
                                style={{
                                  height: "20px",
                                  width: "90px",
                                  backgroundColor: "#1a6985",
                                  color: "white",
                                }}
                              >
                                {result.storageclass}
                              </Avatar>
                            }
                            style={{
                              color: "white",
                              margin: "5px",
                              height: "30px",
                              backgroundColor: result.storageclass
                                .toLowerCase()
                                .includes("standard")
                                ? "#e28743"
                                : result.glacierinfo.includes("expiry date")
                                ? "#e28743"
                                : "#154c79",
                            }}
                          />
                        </Tooltip>
                      </div>
                    ))}
                  </Jumbotron>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div style={{ margin: "10px" }}>
            <TextField
              aria-describedby="basic-addon1"
              label="Keyword"
              id="keyword"
              inputRef={searchTerm}
              margin="5px"
              variant="outlined"
              style={{ width: "100%", marginBottom: "5px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => searchHandler(searchTerm.current.value)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => searchHandler(searchTerm.current.value)}
              style={{ marginLeft: "5px", alignItems: "center" }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={resetHandler}
              style={{ marginLeft: "5px", alignItems: "center" }}
            >
              Reset
            </Button>
          </div>
        </Form>
        History:{" "}
        {historyURL.map((h) => (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "3px" }}
            onClick={(e) => {
              e.preventDefault();
              resetHandler();
              if (h.parent === "/") {
                fetchFolderContent("/");
              } else {
                fetchFolderContent(h.parent);
              }
            }}
          >
            {h.parent}
          </Button>
        ))}
      </div>
      <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          <form>
            <div>
              <b>
                {currentFileCount}/{totalFileCount} Files
              </b>
            </div>
            <Avatar
              style={{
                backgroundColor: "#1a6985",
                height: "2em",
                width: "80%",
                margin: "1em",
              }}
              variant="rounded"
            >
              Current folder: {currentFolder}
            </Avatar>
            <div>
              {filteredFiles.map((c) => (
                <div key={uuidv4()}>
                  <Card
                    style={{
                      maxWidth: "550px",
                      float: "left",
                      marginRight: "10px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                    key={c.key !== undefined ? c.Key : c.Prefix}
                  >
                    {c.Prefix !== undefined ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "2px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          fetchFolderContent(c.Prefix);
                          resetHandler();
                        }}
                      >
                        {c.Prefix}
                      </Button>
                    ) : (
                      <div>
                        <Chip
                          label={getSubString(c.Key)}
                          icon={
                            c.StorageClass.toLowerCase() === "standard" ? (
                              // <CloudDownloadIcon style={{ height: "15px" }} />
                              <Avatar
                                variant="rounded"
                                style={{
                                  height: "20px",
                                  width: "90px",
                                  backgroundColor: "#1a6985",
                                  color: "white",
                                  fontSize: "10px",
                                }}
                              >
                                STANDARD
                              </Avatar>
                            ) : (
                              <Avatar
                                variant="rounded"
                                style={{
                                  height: "20px",
                                  width: "90px",
                                  backgroundColor: "#1a6985",
                                  color: "white",
                                  fontSize: "10px",
                                }}
                              >
                                GLACIER
                              </Avatar>
                            )
                          }
                          color={
                            c.StorageClass.toLowerCase() === "standard"
                              ? "primary"
                              : "secondary"
                          }
                          style={
                            c.StorageClass.toLowerCase() === "standard"
                              ? { backgroundColor: "#f48225" }
                              : { backgroundColor: "#154c79" }
                          }
                          onClick={() => handleDialogOpen(c)}
                        ></Chip>
                      </div>
                    )}
                  </Card>
                </div>
              ))}
            </div>
          </form>
        )}
      </Jumbotron>

      <Dialog
        classes={{ paper: classes.paper }}
        open={s3DialogOpen}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle style={{ width: "100%" }} id="draggable-dialog-title">
          Data details
        </DialogTitle>
        <DialogContent>
          {isDialogLoading ? (
            <Spinner animation="border" />
          ) : (
            <div>
              File:{" "}
              {selectFile.Key !== undefined
                ? getSubString(selectFile.Key)
                : "0"}
              <br />
              Size: {selectFile.Size}
              {user.username ? (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => OpenGaCheckURL(selectFile.Key)}
                  >
                    Check file submission problem
                  </Button>
                </div>
              ) : (
                ""
              )}
              Ongoing request:{" "}
              {fileStatus[0] !== undefined
                ? fileStatus[0]["ongoing-request"]
                : "false"}
              {fileStatus.reponse !== undefined &&
              fileStatus.reponse.toLowerCase() === "in glacier" ? (
                <div>
                  Status: <Chip label={fileStatus.reponse} />
                  <br />
                  <hr />
                  <div>
                    {selectFile.StorageClass.toLowerCase() === "standard" ? (
                      <>
                        <S3AccessComponent fileKey={selectFile.Key} />
                      </>
                    ) : fileStatus[0] !== undefined &&
                      fileStatus[0]["ongoing-request"] === false &&
                      fileStatus[0]["expiry-date"] !== undefined ? (
                      <div></div>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRecall(selectFile.Key)}
                      >
                        Call Back
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {fileStatus[0] !== undefined &&
                  fileStatus[0]["expiry-date"] &&
                  fileStatus[0]["ongoing-request"] ? (
                    <div>
                      Download exp:{" "}
                      <Chip label={fileStatus[0]["expiry-date"]} />
                      <hr />
                      {/* <a
                        href={
                          "https://gpsnet-luv-gnss-data.s3.amazonaws.com/" +
                          selectFile.Key
                        }>
                        <Button color="success">Download</Button>
                      </a> */}
                      <S3AccessComponent fileKey={selectFile.Key} />
                    </div>
                  ) : (
                    <div>
                      Status: <Chip label={"Restore in progress"} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
