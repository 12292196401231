import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

function TimeSerieCards({ d, loading }) {
  if (loading) {
    return <h2>loading...</h2>;
  }
  return (
    <div>
      <Card
        style={{
          width: "280px",
          float: "left",
          marginRight: "10px",
          marginBottom: "10px",
          padding: "10px",
        }}
        key={d.id}
      >
        <Card.Title>{d.name}</Card.Title>
        <Card.Text>
          {d.code}
          <br />
          {d.receiver}
          <br />
          {d.antenna}
          <br />
        </Card.Text>
        <div
          style={{
            display: "inline-block",
            height: "20em",
            width: "13em",
          }}
        >
          <img
            src={`https://gnss-analysis.s3-ap-southeast-2.amazonaws.com/results/combination/PLOTS/${d.code}_mod.gif`}
            alt={`${d.code} time series graph not available`}
            height="5em"
          />
        </div>

        <Link to={`/gaGraph/${d.code}`}>
          <Button variant="contained" color="primary">
            Coordinate Analysis
          </Button>
        </Link>
        <br />
        <Link
          to={{
            pathname: `https://cors1.vicpos.com.au/REG13/${d.code}.pdf`,
          }}
          target="_blank"
        >
          <Button variant="contained" color="primary">
            Reg 13
          </Button>
        </Link>
      </Card>
    </div>
  );
}

export default TimeSerieCards;
