// below detail are used to allow user to download the s3 bucket file without public access.

const awsConfig = {
  accessKeyId: "AKIARJESMFTB65SHCJTD",
  secretAccessKey: "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",
  region: "ap-southeast-2",
  bucketName: "gpsnet-luv-gnss-data",
};

export default awsConfig;
