import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, Jumbotron, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataOverall from "./DataOverall";
import { CorsAzureSydTestContext } from "../contexts/CorsAzureSydTestContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileOverview from "./FileOverview";

import { TextField, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

function CorsAzureSydTest() {
  //set notification message
  var date = new Date();
  let test_time = new Date(date).toLocaleDateString("en-AU");
  const notifyDataInfo = () =>
    toast.info(
      <div>
        All data are collected from previous 30 days.
        <br />
        Last updated: {test_time}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        containerId: "A",
      }
    );

  const notifySearchResult = () =>
    toast(
      <div>
        {result} result{result > 1 ? "s" : ""} found
        <br />
        Last updated: {test_time}
      </div>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        containerId: "B",
      }
    );
  const [isLoading, setIsLoading] = useState(true);

  //initial CorsSydContext props
  const {
    corsData,
    loading,
    filteredCorsData,
    setFilteredCorsData,
    over95RCheck,
    over95TCheck,
    under95RCheck,
    under95TCheck,
    setOver95RCheck,
    setOver95TCheck,
    setUnder95RCheck,
    setUnder95TCheck,
  } = useContext(CorsAzureSydTestContext);

  //init search key and local state copy
  const [tempState, setTempState] = useState(filteredCorsData);
  const [searchKey, setSearchKey] = useState("");

  //filter Rinex over 95%
  useEffect(() => {
    setTempState(filteredCorsData);
    if (over95RCheck) {
      const filterData = filteredCorsData.filter((d) => d.rinex >= 95);
      setFilteredCorsData(filterData);
    } else {
      setFilteredCorsData(tempState);
    } // eslint-disable-next-line
  }, [over95RCheck]);

  //filter Rinex under 95%
  useEffect(() => {
    setTempState(filteredCorsData);
    if (under95RCheck) {
      const filterData = filteredCorsData.filter((d) => d.rinex < 95);

      setFilteredCorsData(filterData);
    } else {
      setFilteredCorsData(tempState);
    } // eslint-disable-next-line
  }, [under95RCheck]);

  //filter t02 over 95%
  useEffect(() => {
    setTempState(filteredCorsData);
    if (over95TCheck) {
      const filterData = filteredCorsData.filter((d) => d.t02 >= 95);

      setFilteredCorsData(filterData);
    } else {
      setFilteredCorsData(tempState);
    } // eslint-disable-next-line
  }, [over95TCheck]);

  //filter t02 under 95%
  useEffect(() => {
    setTempState(filteredCorsData);
    if (under95TCheck) {
      const filterData = filteredCorsData.filter((d) => d.t02 < 95);

      setFilteredCorsData(filterData);
    } else {
      setFilteredCorsData(tempState);
    } // eslint-disable-next-line
  }, [under95TCheck]);

  //remove loading spinner when corsData is ready
  useEffect(() => {
    setIsLoading(loading); // eslint-disable-next-line
    setFilteredCorsData(corsData); // eslint-disable-next-line
  }, [corsData]);

  //set toast notification
  useEffect(() => {
    notifyDataInfo(); // eslint-disable-next-line
  }, []);

  //get the card list count
  let result = filteredCorsData.length;
  useEffect(() => {
    if (result !== 0) {
      notifySearchResult();
    } // eslint-disable-next-line
  }, [result]);

  // set all card figure below for Overall Card
  const avgRinex = filteredCorsData
    .filter((fd) => fd.rinex !== 0 || fd.t02 !== 0)
    .reduce(
      (acc, i) =>
        acc +
        i.rinex /
          filteredCorsData.filter((fd) => fd.rinex !== 0 || fd.t02 !== 0)
            .length,
      0
    );
  const avgT02 = filteredCorsData
    .filter((fd) => fd.rinex !== 0 || fd.t02 !== 0)
    .reduce(
      (acc, i) =>
        acc +
        i.t02 /
          filteredCorsData.filter((fd) => fd.rinex !== 0 || fd.t02 !== 0)
            .length,
      0
    );

  //init all filter count
  let under95Rinex = filteredCorsData.filter((d) => d.rinex < 95).length;
  let over95Rinex = filteredCorsData.filter((d) => d.rinex >= 95).length;
  let under95T02 = filteredCorsData.filter((d) => d.t02 < 95).length;
  let over95T02 = filteredCorsData.filter((d) => d.t02 >= 95).length;

  //prevent page reload when submit form
  const handlePress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  };

  //reset search and filter value
  const clearSearch = () => {
    setFilteredCorsData(corsData);
    setOver95RCheck(false);
    setUnder95RCheck(false);
    setOver95TCheck(false);
    setUnder95TCheck(false);
    setSearchKey("");
  };

  //search by keyword
  useEffect(() => {
    setFilteredCorsData(corsData);
    setFilteredCorsData(
      corsData.filter(
        (d) =>
          d.name.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.code.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.receiver.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.antenna.toLowerCase().includes(searchKey.toLowerCase())
      )
    ); // eslint-disable-next-line
  }, [searchKey]);

  return (
    <div>
      <br />
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position="top-left"
        autoClose={30000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ textAlign: "left" }}
      />
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position="bottom-center"
        autoClose={1800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ textAlign: "left" }}
      />

      <h1>GPSnet CORS Azure Sydney Datacentre</h1>
      <div></div>
      <div className="search">
        <form>
          <div>
            <TextField
              aria-describedby="basic-addon1"
              label="Keyword"
              aria-label="Keyword"
              id="keyword"
              value={searchKey}
              onKeyPress={handlePress}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ width: "85%", marginRight: "5px", marginBottom: "5px" }}
            />
            <Button variant="contained" color="secondary" onClick={clearSearch}>
              Reset
            </Button>
          </div>
        </form>
      </div>
      <br />
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <FileOverview data={avgRinex} title={"Rinex Overall"} />
            <FileOverview data={avgT02} title={"T02 Overall"} />
          </Jumbotron>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            <Button
              key="button1"
              style={{
                width: "300px",
                height: "200px",
                margin: "20px",
                float: "left",
              }}
              onClick={() => {
                setOver95RCheck(!over95RCheck);
              }}
              variant="contained"
              color={over95RCheck ? "secondary" : "primary"}
              disabled={
                under95RCheck ||
                over95TCheck ||
                under95TCheck ||
                over95Rinex === 0
              }
            >
              <h3>
                Over 95% Rinex{" "}
                <sup>
                  <Badge variant="danger">{over95Rinex}</Badge>
                </sup>
              </h3>
            </Button>
            <Button
              key="button2"
              onClick={() => setUnder95RCheck(!under95RCheck)}
              variant="contained"
              color={under95RCheck ? "secondary" : "primary"}
              disabled={
                over95RCheck ||
                over95TCheck ||
                under95TCheck ||
                under95Rinex === 0
              }
              style={{
                width: "300px",
                height: "200px",
                margin: "20px",
                float: "left",
              }}
            >
              <h3>
                Under 95% Rinex{" "}
                <sup>
                  <Badge variant="danger">{under95Rinex}</Badge>
                </sup>
              </h3>
            </Button>
            <Button
              key="button3"
              onClick={() => setOver95TCheck(!over95TCheck)}
              variant="contained"
              color={over95TCheck ? "secondary" : "primary"}
              disabled={
                over95RCheck ||
                under95RCheck ||
                under95TCheck ||
                over95T02 === 0
              }
              style={{
                width: "300px",
                height: "200px",
                margin: "20px",
                float: "left",
              }}
            >
              <h3>
                Over 95% T02{" "}
                <sup>
                  <Badge variant="danger">{over95T02}</Badge>
                </sup>
              </h3>
            </Button>
            <Button
              key="button4"
              onClick={() => setUnder95TCheck(!under95TCheck)}
              variant="contained"
              color={under95TCheck ? "secondary" : "primary"}
              disabled={
                over95RCheck ||
                under95RCheck ||
                over95TCheck ||
                under95T02 === 0
              }
              style={{
                width: "300px",
                height: "200px",
                margin: "20px",
                float: "left",
              }}
            >
              <h3>
                Under 95% T02{" "}
                <sup>
                  <Badge variant="danger">{under95T02}</Badge>
                </sup>
              </h3>
            </Button>
          </Jumbotron>

          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            {filteredCorsData.map((d) => (
              <Card
                style={{
                  width: "280px",
                  float: "left",
                  marginRight: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
                key={d.id}
              >
                <Card.Title>{d.name}</Card.Title>
                <Card.Text>
                  {d.code}
                  <br />
                  {d.receiver}
                  <br />
                  {d.antenna}
                  <br />
                </Card.Text>
                <DataOverall data={d.rinex} name="Rinex" />

                <DataOverall data={d.t02} name="T02" />
                <Link to={`/status/azuresyd/${d.code}`}>
                  <Button variant="contained" color="primary">
                    Details
                  </Button>
                </Link>
                <br />
                <Link
                  to={{
                    pathname: `https://cors1.vicpos.com.au/REG13/${d.code}.pdf`,
                  }}
                  target="_blank"
                >
                  <Button variant="contained" color="primary">
                    Reg 13
                  </Button>
                </Link>
              </Card>
            ))}
          </Jumbotron>
        </div>
      )}
    </div>
  );
}

export default CorsAzureSydTest;
