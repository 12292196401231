import React from "react";
import AWS from "aws-sdk";
import awsConfig from "./aws-config";

const handleFileDownload = async (fileKey) => {
  try {
    // Configure AWS SDK with your access keys and region
    AWS.config.update({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey,
      region: awsConfig.region,
      s3ForcePathStyle: true, // Use path-style URLs to avoid CORS issues
      signatureVersion: "v4", // Use signature version 4 for pre-signed URLs
    });

    const s3 = new AWS.S3();

    // Generate a pre-signed URL for the file download
    const params = { Bucket: awsConfig.bucketName, Key: fileKey };
    const url = await s3.getSignedUrlPromise("getObject", params);

    // Use the pre-signed URL to initiate the download
    const response = await fetch(url);
    const fileContent = await response.arrayBuffer();

    const fileName = fileKey.substring(fileKey.lastIndexOf("/") + 1);
    const element = document.createElement("a");
    const file = new Blob([fileContent], { type: "application/octet-stream" });
    element.href = URL.createObjectURL(file);
    element.download = fileName; // Use the last bit of fileKey as the filename
    document.body.appendChild(element);
    element.click();
  } catch (error) {
    console.error("Error fetching file content:", error);
  }
};

export default handleFileDownload;
