import { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import moment from "moment";

export default function fetchRxInfo(site_code) {
  const [latestReceiver, setLatestReceiver] = useState(null);
  const [latestAntenna, setLatestAntenna] = useState(null);
  const [siteIdentification, setSiteIdentification] = useState(null);
  const [siteImage, setSiteImage] = useState("");

  const fetchData = async () => {
    let url =
      "https://gws.geodesy.ga.gov.au/siteLogs/search/findByFourCharacterId?id=" +
      site_code.site_code;
    const response = await axios.get(url).then((res) => {
      // console.log(res.data);
      setSiteIdentification(res.data.siteIdentification);
      const antennas = res.data.gnssAntennas;
      let la = null; // latest antenna
      let AlatestDateInstalled = new Date(0);
      antennas.forEach((a) => {
        const dateInstalled = new Date(a.dateInstalled);
        if (dateInstalled > AlatestDateInstalled) {
          la = a;
          AlatestDateInstalled = dateInstalled;
        }
      });
      setLatestAntenna(la);
      const receivers = res.data.gnssReceivers;
      let lr = null; //latest receiver
      let RlatestDateInstalled = new Date(0);
      receivers.forEach((r) => {
        const dateInstalled = new Date(r.dateInstalled);
        if (dateInstalled > RlatestDateInstalled) {
          lr = r;
          RlatestDateInstalled = dateInstalled;
        }
      });
      setLatestReceiver(lr);
      // Get the XML content from the response
      const xmlContent = res.data.siteLogText;
      // Extract the URL from the XML content
      const regex = /<geo:fileReference ns9:href="([^"]+)"/;
      const match = xmlContent.match(regex);
      const imageUrl = match ? match[1] : null;
      // retreive the image url
      setSiteImage(imageUrl);
    });
  };

  useEffect(() => {
    fetchData();
    // fetchXMLData();
  }, []);

  return (
    <div>
      Site Mgmt Info
      {latestReceiver ? (
        <div>
          <a href={siteImage} target="_blank">
            <img src={siteImage} />
          </a>
          <table>
            <tr>
              <td>
                <b>Site Ins Date:</b>
              </td>
              <td>
                {moment(siteIdentification.dateInstalled).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr>
              <td>
                <b>DOMES #:</b>
              </td>
              <td>{siteIdentification.iersDOMESNumber}</td>
            </tr>

            <tr>
              <td>
                <b>Status:</b>
              </td>
              <td>
                {latestReceiver.dateRemoved == null
                  ? "Current"
                  : "Decommissioned"}
              </td>
            </tr>
            <tr>
              <td>
                <b>Ant:</b>
              </td>
              <td>{latestAntenna.type}</td>
            </tr>
            <tr>
              <td>
                <b>Ant Ins Date:</b>
              </td>
              <td>
                {moment(latestAntenna.dateInstalled).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr>
              <td>
                <b>Rx:</b>
              </td>
              <td>{latestReceiver.type}</td>
            </tr>
            <tr>
              <td>
                <b>Firmware:</b>
              </td>
              <td>{latestReceiver.firmwareVersion}</td>
            </tr>
            <tr>
              <td>
                <b>FW Ins Date:</b>
              </td>
              <td>
                {moment(latestReceiver.dateInstalled).format("YYYY-MM-DD")}
              </td>
            </tr>

            <tr>
              <td>
                <b>Rx Serial:</b>
              </td>
              <td>{latestReceiver.serialNumber}</td>
            </tr>
            <tr>
              <td>
                <b>Decommission Date:</b>
              </td>
              <td>
                {moment(latestReceiver.dateRemoved).isValid()
                  ? moment(latestReceiver.dateRemoved).format("YYYY-MM-DD")
                  : ""}
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <Spinner animation="border" variant="primary" />
      )}
    </div>
  );
}
