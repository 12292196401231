import React from "react";
function GaGraph() {
  const currURL = window.location.href;
  const len = currURL.length;
  const first = len - 4;
  const station = currURL.slice(first, len);
  const url1 =
    "https://s3-ap-southeast-2.amazonaws.com/gnss-analysis/status/net/reg13/yearly_" +
    station +
    "_neh.png";
  const url2 =
    "https://s3-ap-southeast-2.amazonaws.com/gnss-analysis/status/rapid/reg13/yearly_Diff_reg13_" +
    station +
    "_neh.png";
  const url3 =
    "https://gnss-analysis.s3-ap-southeast-2.amazonaws.com/results/combination/PLOTS/" +
    station +
    "_mod.gif";

  return (
    <div>
      <div>
        <img src={url3} style={{ width: "60%" }} alt="CORS Movement Graph" />
      </div>
      <div>
        <img
          src={url2}
          style={{ width: "70%" }}
          alt="Diff between Reg13 and PPP"
        />
      </div>
      <div>
        <img
          src={url1}
          style={{ width: "70%" }}
          alt="Diff between Nt_SNX and Reg13"
        />
      </div>
    </div>
  );
}
export default GaGraph;
