import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../contexts/ApiContext";
import { UserContext } from "../contexts/UserContext";
import { Modal, Form, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { TextField, Button, Paper } from "@material-ui/core";

function Login() {
  const [details, setDetails] = useState({ identifier: "", password: "" });
  const { user, setUser } = useContext(UserContext);
  const { apis } = useContext(ApiContext);
  const [loginError, setLoginError] = useState("");
  const [show, setShow] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const notifyDataInfo = (name) =>
    toast(<div>Welcome {name}</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      containerId: "A",
    });

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    await fetchUser();
    // Get the previousPage query parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const previousPage = urlParams.get("previousPage");

    // Redirect the user to the previous page after login
    if (localStorage.getItem("token") !== "") {
      if (previousPage) {
        window.location.href = previousPage;
      }
    }
  };

  const logoutHandler = (e) => {
    localStorage.setItem("token", "");
    localStorage.setItem("username", "");
    localStorage.setItem("email", "");
    setUser({ token: "", username: "", email: "" });
  };

  const closeModal = () => {
    setLoginError("");
    setShow(!show);
    setLoggingIn(false);
  };

  // useEffect(() => {
  //   setLoggingIn(false);
  //   if (user.username) {
  //     notifyDataInfo();
  //   } // eslint-disable-next-line
  // }, [user]);

  useEffect(() => {
    if (localStorage.getItem("username") !== "") {
      setUser({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      });
    } // eslint-disable-next-line
  }, []);

  const fetchUser = async () => {
    // eslint-disable-next-line
    const data = await axios
      .post(apis.gpsCMS_API + "/auth/local", details)
      .then((res) => {
        setUser({
          token: res.data.jwt,
          username: res.data.user.username,
          email: res.data.user.email,
        });
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("username", res.data.user.username);
        localStorage.setItem("email", res.data.user.email);
        notifyDataInfo(res.data.user.username);
      })
      .catch((err) => {
        console.log(err);
        setLoginError("Make sure your username and password are correct!!");
        setShow(true);
      });

    setDetails({ identifier: "", password: "" });
  };

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ textAlign: "left" }}
      />
      {user.username ? (
        <div style={{ alignItems: "center", margin: "10px" }}>
          <Paper
            elevation={3}
            style={{
              padding: "10px",
              width: "60%",
              verticalAlign: "center",
              alignItems: "center",
              marginLeft: "20%",
            }}
          >
            <h1>Hi! {user.username}</h1>
            <Button
              variant="contained"
              color="secondary"
              onClick={logoutHandler}
            >
              Logout
            </Button>
          </Paper>
        </div>
      ) : (
        <div>
          <h1>login</h1>
          <br />
          <Paper
            elevation={3}
            style={{
              padding: "10px",
              width: "60%",
              verticalAlign: "center",
              alignItems: "center",
              marginLeft: "20%",
            }}
          >
            <Form onSubmit={submitHandler}>
              <div style={{ margin: "5px" }}>
                <TextField
                  variant="outlined"
                  value={details.identifier}
                  label="User Name"
                  onChange={(e) =>
                    setDetails({ ...details, identifier: e.target.value })
                  }
                />
              </div>
              <div style={{ margin: "5px" }}>
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={details.password}
                  onChange={(e) =>
                    setDetails({ ...details, password: e.target.value })
                  }
                />
              </div>
              <Button variant="contained" color="primary" type="submit">
                {loggingIn ? <Spinner animation="grow" /> : "Log In"}
              </Button>

              {loginError !== "" ? (
                <Modal show={show}>
                  <Modal.Header>Login Failed</Modal.Header>
                  <Modal.Body>{loginError}</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                ""
              )}
            </Form>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default Login;
