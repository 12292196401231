import React from "react";
import { Card } from "react-bootstrap";

function ReportCardsLastMnth({ d, dataCentre }) {
  return (
    <div>
      <Card
        key={d.id}
        style={{
          width: "280px",
          float: "left",
          padding: "5px",
          margin: "5px",
        }}
      >
        <Card.Title>
          {d.fileType} - {dataCentre}
        </Card.Title>
        <Card.Body>
          <h4
            style={{
              color:
                d.percentage >= 95
                  ? "green"
                  : d.percentage >= 80
                  ? "#067BC2"
                  : "red",
            }}
          >
            {Math.round(d.percentage * 100) / 100} %
          </h4>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ReportCardsLastMnth;
