import React, { useEffect } from "react";
import StatusAzureMel from "./components/StatusAzureMel";
import StatusAzureSyd from "./components/StatusAzureSyd";
import Navi from "./components/Navi";
import Home from "./components/Home";
import GaGraph from "./components/GaGraph";
import CorsAzureMel from "./components/CorsAzureMel";
import CorsAzureSyd from "./components/CorsAzureSyd";
import CorsAzureMelTest from "./components/CorsAzureMelTest";
import CorsAzureSydTest from "./components/CorsAzureSydTest";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import ApiContextProvider from "./contexts/ApiContext";
import UserContextProvider from "./contexts/UserContext";
import CorsAzureMelContext from "./contexts/CorsAzureMelContext";
import CorsAzureSydContext from "./contexts/CorsAzureSydContext";
import CorsAzureMelTestContext from "./contexts/CorsAzureMelTestContext";
import CorsAzureSydTestContext from "./contexts/CorsAzureSydTestContext";
import CorsMap from "./components/CORSMap";
import Report from "./components/Report";
import Login from "./components/Login";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import CorsTimeSeries from "./components/CorsTimeSeries";
import DataRepo from "./components/DataRepo";
import Reg13Check from "./components/Reg13Checker";
import IsrManager from "./components/IsrManager";
import PubIsrManager from "./components/PubIsrManager";
import Aws_test_lambda from "./components/Aws_test_lambda";
import FetchRxInfo from "./components/FetchRxInfo";
import PowerNotification from "./components/PowerNotification";
import pduManager from "./components/PduManager";
import PduManager from "./components/PduManager";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-WB53WLB",
  };
  useEffect(() => {
    document.title = "GPSnet App";
    ReactGA.initialize("UA-197197914-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    TagManager.initialize(tagManagerArgs); // eslint-disable-next-line
  }, []);
  return (
    <div className="App">
      <UserContextProvider>
        <ApiContextProvider>
          <CorsAzureMelContext>
            <CorsAzureSydContext>
              <CorsAzureMelTestContext>
                <CorsAzureSydTestContext>
                  <Router>
                    <Navi />
                    <Switch>
                      <Route path="/" exact component={Home} />
                      {/* <Route
                                path="/status/Mel/:station"
                                component={StatusMel}
                              /> */}
                      <Route
                        path="/status/AzureMel/:station"
                        component={StatusAzureMel}
                      />
                      <Route
                        path="/status/AzureSyd/:station"
                        component={StatusAzureSyd}
                      />
                      <Route path="/gaGraph/:station" component={GaGraph} />
                      <Route path="/corsAzureMel" component={CorsAzureMel} />
                      <Route path="/corsAzureSyd" component={CorsAzureSyd} />
                      <Route
                        path="/corsAzureMelTest"
                        component={CorsAzureMelTest}
                      />
                      <Route
                        path="/corsAzureSydTest"
                        component={CorsAzureSydTest}
                      />
                      <Route path="/CorsMap" component={CorsMap} />
                      <Route path="/Report" component={Report} />
                      <Route path="/DataRepo" component={DataRepo} />
                      <Route path="/Login" component={Login} />
                      <Route path="/Reg13Checker" component={Reg13Check} />
                      <Route
                        path="/CorsTimeSeries"
                        component={CorsTimeSeries}
                      />
                      <Route path="/IsrManager" component={IsrManager} />
                      <Route path="/PubIsrManager" component={PubIsrManager} />
                      <Route
                        path="/AwsTestLambda"
                        component={Aws_test_lambda}
                      />
                      <Route path="/FetchRxInfo" component={FetchRxInfo} />
                      <Route
                        path="/PowerNotification"
                        component={PowerNotification} />
                      <Route
                        path="/PduManager"
                        component={PduManager} />
                    </Switch>
                  </Router>
                </CorsAzureSydTestContext>
              </CorsAzureMelTestContext>
            </CorsAzureSydContext>
          </CorsAzureMelContext>
        </ApiContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
