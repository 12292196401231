import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Jumbotron } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TextField, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TimeSerieCards from "./TimeSerieCards";
import { CorsAzureMelContext } from "../contexts/CorsAzureMelContext";
// import { CorsAzureSydContext } from "../contexts/CorsAzureSydContext";

function CorsTimeSeries() {
  //set notification message
  var date = new Date();
  let test_time = new Date(date).toLocaleDateString("en-AU");
  const notifyDataInfo = () =>
    toast.info(
      <div>
        All data are collected from previous 30 days.
        <br />
        Last updated: {test_time}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        containerId: "A",
      }
    );

  const notifySearchResult = () =>
    toast(
      <div>
        {result} result{result > 1 ? "s" : ""} found
        <br />
        Last updated: {test_time}
      </div>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        containerId: "B",
      }
    );
  const [isLoading, setIsLoading] = useState(true);

  //initial CorsAzureMelContext props
  const { corsData, loading, filteredCorsData, setFilteredCorsData } =
    useContext(CorsAzureMelContext);
  // useContext(CorsAzureSydContext)

  //init search key and local state copy
  const [searchKey, setSearchKey] = useState("");

  //remove loading spinner when corsData is ready
  useEffect(() => {
    setIsLoading(loading); // eslint-disable-next-line
    setFilteredCorsData(corsData); // eslint-disable-next-line
  }, [corsData]);

  //set toast notification
  useEffect(() => {
    notifyDataInfo(); // eslint-disable-next-line
  }, []);

  //get the card list count
  let result = filteredCorsData.length;
  useEffect(() => {
    if (result !== 0) {
      notifySearchResult();
    } // eslint-disable-next-line
  }, [result]);

  //prevent page reload when submit form
  const handlePress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  };

  //reset search and filter value
  const clearSearch = () => {
    setFilteredCorsData(corsData);
    setSearchKey("");
  };

  //search by keyword
  useEffect(() => {
    setFilteredCorsData(corsData);
    setFilteredCorsData(
      corsData.filter(
        (d) =>
          d.name.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.code.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.receiver.toLowerCase().includes(searchKey.toLowerCase()) ||
          d.antenna.toLowerCase().includes(searchKey.toLowerCase())
      )
    ); // eslint-disable-next-line
  }, [searchKey]);

  return (
    <div>
      <br />

      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        position="bottom-center"
        autoClose={1800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ textAlign: "left" }}
      />

      <h1>GPSnet CORS Time Series</h1>
      <div></div>
      <div className="search">
        <form>
          <div>
            <TextField
              aria-describedby="basic-addon1"
              label="Keyword"
              aria-label="Keyword"
              id="keyword"
              value={searchKey}
              onKeyPress={handlePress}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ width: "85%", marginRight: "5px" }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={clearSearch}
              style={{ marginTop: "10px" }}>
              Reset
            </Button>
          </div>
        </form>
      </div>
      <br />
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Jumbotron style={{ overflow: "auto", padding: "50px" }}>
            {filteredCorsData.map((d) => (
              <TimeSerieCards d={d} loading={loading} />
            ))}
          </Jumbotron>
        </div>
      )}
    </div>
  );
}

export default CorsTimeSeries;
