import React, { createContext, useState } from "react";

export const ApiContext = createContext();
const ApiContextProvider = (props) => {
  // eslint-disable-next-line
  const [apis, setApis] = useState({
    gpsnetMelAPI: "https://gpsapi.vicpos.com.au/mel",
    gpsnetSydAPI: "https://gpsapi.vicpos.com.au/syd",
    gpsnetAzureMelAPI: "https://gpsapi.vicpos.com.au/azure_mel",
    gpsnetAzureSydAPI: "https://gpsapi.vicpos.com.au/azure_syd",
    gaAPI: "https://gws.geodesy.ga.gov.au/corsSites/search/",
    gpsCMS_API: "https://cms.vicpos.com.au",
    s3api: "https://s3api.vicpos.com.au",
  });

  return (
    <ApiContext.Provider value={{ apis }}>{props.children}</ApiContext.Provider>
  );
};

export default ApiContextProvider;
