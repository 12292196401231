import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";
import { Button, Spinner, Tabs, Tab } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@material-ui/core";

const dataReducer = (filteredData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredData;
  }
};

const PduManager = () => {
  const user = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const url = "https://s3api.vicpos.com.au";
  const secret =
    "bc1c0194cf84447a8a18e2a56e5178f8d5879a379481d6d7d74d09b812876d4d";
  const [isLoading, setIsLoading] = useState(true);
  const [d_tab, setD_tab] = useState("pduOverview");
  const keyword = useRef("");
  const [assets, setAssets] = useState([]);
  const [pdu_data, setPduData] = useState([]);
  const [mergeData, setMergeData] = useState([]);
  const [filteredData, dispatch] = useReducer(dataReducer, []);
  const [pduActionData, setPduActionData] = useState([]);

  const fetchPduAction = async () => {
    axios.get(url + "/get_pdu_action?secret=" + secret).then((response) => {
      setPduActionData(response.data);
    });
  };

  useEffect(() => {
    const fetchAsset = async () => {
      var options = {
        method: "GET",
        url: `https://asset.vicpos.com.au/api/v1/hardware?category_id=19`,
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTY1Y2Q2ZjAyNjA2MTUzM2NiZWVjMmI5ZmJhMGM5ZTRlODBlZTRlMDFmMmYxOGM3YzFmY2VmZGM4YzNjY2I2NDZjNWU2ZmViZDU3MjYwNjkiLCJpYXQiOjE2NTg4MDU3NTUuNzY1MjMxLCJuYmYiOjE2NTg4MDU3NTUuNzY1MjM1LCJleHAiOjIxMzIxOTEzNTUuNzUxMTExLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.qmftEyYDG-5T4MeYPy1kU3rL4nR7YL0GeMGCx18GzgMNGmxlkHbZ9CWdBV1NsuEKayUv0Pg4NIn4JeYPSs-jPnZA5gfV02v7LchplnNCkMNQNjZR4zqPXCciYgTzTKnz-8Moxn7Pou15fEHy3BWSe-3FD3dW_BGd17IqZ8EoyQWfkzNYS087L7ZQtVaXD_8TGbssDUZpCLETQQHYdHptpleuFkHrjvLhnPGvs9rbxrMVNp8RIfxYNxo5SgwyYYJupAaRl5fyDch7yCllM-5UCjanasC5e3xEq98ZIbWLiYwuBUpfq-AUxRL9_JG0U89eUAIgLRBcAx1JOopFZyEYeoyO7DUa5voQKZv0j5ElS-J1xaBtXJ3EgvznkIO6_2KI6i7u2Mhbh3ScTtsO7uObED7yc31H_gaiyUAF10wtDdw-60_BiN73EP-EaNs-tRgW6FdQ5TxlTAk4A4aADiTGUEhU2fvzWc-G5y-K1-E37NNEvraku-TSJr374N8IRpEx9dS9UCQVEbPJbAtEVNIjn6nG1D2ZGYaSUo-8r2mS_q-DmQ_54zYDfA3Oyfdr2j2dzkiwjnWpW3XILxdhF8-Ek9djiGqERK86XJBioaGv5ygaGhN3tYtYFa01oXZ2aAs9V-0MU4rqAkpeINoaBip-XcmOnIDAiwOcS4DcbblJjF8",
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          var assetData = response.data.rows;
          assetData = assetData.filter(
            (asset) =>
              asset["custom_fields"]["Inside IP Address"]["value"] !== "" &&
              asset["status_label"]["name"].toLowerCase() == "ready to deploy"
          );
          // dispatch({ type: "initial", payload: assetData });
          setAssets(assetData);
        })
        .catch(function (error) {
          console.error(error);
        });
    };
    fetchAsset();
    fetchPduAction();
  }, []);

  useEffect(() => {
    const fetchPduDataSummary = async() => {
      await axios.get(url+"/get_all_pdu_info_summary").then((response) => {
        setPduData(response.data);
    })};

    const mergePduData = async () => {
      // update asset data with pdu data matching the ip address
      for (const asset of assets) {
        // Check if custom_fields exists and has "Inside IP Address"
        if (asset.custom_fields && asset.custom_fields["Inside IP Address"] !== undefined) {
          const pdu = pdu_data.find(pdu => pdu.pdu_ip === asset.custom_fields["Inside IP Address"].value);
  
          // insert the asset.name into the pdu_data
          if (pdu) {
            pdu.name = asset.name;
            pdu.id = asset.id;
          }
        }
      }
      // remove name == "" from pdu_data or name == undefined
      let temp = pdu_data.filter((pdu) => pdu.name !== undefined);
      dispatch({ type: "initial", payload: temp });
      setMergeData(temp);
    };
  
    fetchPduDataSummary();
    mergePduData();
    setIsLoading(false);

  }, [assets, isLoading]);

  const notifyDataInfo = (message) =>
    toast.success(
      <div>{message}</div>,

      {
        position: toast.POSITION.TOP_RIGHT,
        containerId: "A",
      }
    );

  const searchHandler = (st) => {
    const newList = mergeData.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    dispatch({ type: "keywords", payload: newList });
  };

  const cancelPduAction = async (
    device_name,
    ip_adress,
    pdu_outlet,
    pdu_action
  ) => {
    try {
      axios
        .post(url + "/delete_pdu_action?secret=" + secret, {
          device_name: device_name,
          ip: ip_adress,
          outlet: pdu_outlet,
          action: pdu_action,
        })
        .then((response) => {
          notifyDataInfo(device_name + " PDU action cancelled");
          fetchPduAction();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePduAction = async (
    device_name,
    ip_adress,
    pdu_outlet,
    pdu_action
  ) => {
    try {
      const response = await axios
        .post(url + "/save_pdu_action?secret=" + secret, {
          device_name: device_name,
          ip: ip_adress,
          outlet: pdu_outlet,
          action: pdu_action,
        })
        .then((response) => {
          notifyDataInfo(device_name + " PDU action scheduled successfully");
          fetchPduAction();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const pduActionData_columns = [
    {
      dataField: "device_name",
      text: "Device Name",
      sort: true,
    },
    {
      dataField: "ip",
      text: "IP Address",
      sort: true,
    },
    {
      dataField: "outlet",
      text: "Outlet",
      sort: true,
    },
    {
      dataField: "action",
      text: "action",
      sort: true,
    },
    {
      text: "Action",
      formatter: (cell, row) => (
        <Button
          variant="danger"
          onClick={() =>
            cancelPduAction(row.device_name, row.ip, row.outlet, row.action)
          }>
          Cancel
        </Button>
      ),
    },
  ];

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      title: (cell, row) => row.pdu_ip, // Set the tooltip title to the IP address
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
    },
    {
      dataField: "fw_version",
      text: "FW",
      sort: true,
    },
    {
      dataField: "temperature_sensor",
      text: "Temp",
      sort: true,
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
      formatter: (cell, row) => {
        if (row.sensor && row.sensor.length > 1) {
          const tempSensor = row.sensor.find(sensor => sensor.sensor_type === "Temperature");
          if (tempSensor) {
            return (
              <span
                style={{
                  color: tempSensor.reading.toLowerCase().includes("warning")
                    ? "red"
                    : "green",
                }}>
                {`${tempSensor.reading}`}
              </span>
            );
          }
        }
        return "N/A";
      },
    },
    {
      dataField: "humidity_sensor",
      text: "Humidity",
      sort: true,
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
      formatter: (cell, row) => {
        if (row.sensor && row.sensor.length > 1) {
          const humiditySensor = row.sensor.find(sensor => sensor.sensor_type === "Relative Humidity");
          if (humiditySensor) {
            return (
              <span
                style={{
                  color: humiditySensor.reading.toLowerCase().includes("warning")
                    ? "red"
                    : "green",
                }}>{`${humiditySensor.reading}`}</span>
            );
          }
        }
        return "N/A";
      },
    },
    // {
    //   dataField: "sensor",
    //   text: "Temp",
    //   sort: true,
    //   headerClasses: "hide-on-mobile", // Show the header on mobile devices
    //   classes: "hide-on-mobile", // Hide the column on mobile devices
    //   formatter: (cell, row) => {
    //     if (row.sensor && row.sensor.length > 1) {
    //       if (row.sensor[0].sensor_type === "Temperature") {
    //         return (
    //           <span
    //             style={{
    //               color: row.sensor[0].reading
    //                 .toLowerCase()
    //                 .includes("warning")
    //                 ? "red"
    //                 : "green",
    //             }}>
    //             {`${row.sensor[0].reading}`}
    //           </span>
    //         );
    //       } else if (
    //         row.sensor[0].sensor_type !== "Temperature" &&
    //         row.sensor[1] !== undefined &&
    //         row.sensor[1].sensor_type === "Temperature"
    //       ) {
    //         return (
    //           <span
    //             style={{
    //               color: row.sensor[1].reading
    //                 .toLowerCase()
    //                 .includes("warning")
    //                 ? "red"
    //                 : "green",
    //             }}>
    //             {`${row.sensor[1].reading}`}
    //           </span>
    //         );
    //       }
    //     } else {
    //       return "N/A";
    //     }
    //   },
    // },
    // {
    //   dataField: "sensor",
    //   text: "Humidity",
    //   sort: true,
    //   headerClasses: "hide-on-mobile", // Show the header on mobile devices
    //   classes: "hide-on-mobile", // Hide the column on mobile devices
    //   formatter: (cell, row) => {
    //     if (row.sensor && row.sensor.length > 1) {
    //       if (row.sensor[1].sensor_type === "Relative Humidity") {
    //         return (
    //           <span
    //             style={{
    //               color: row.sensor[1].reading
    //                 .toLowerCase()
    //                 .includes("warning")
    //                 ? "red"
    //                 : "green",
    //             }}>{`${row.sensor[1].reading}`}</span>
    //         );
    //       } else if (row.sensor[0].sensor_type === "Relative Humidity") {
    //         return (
    //           <span
    //             style={{
    //               color: row.sensor[0].reading
    //                 .toLowerCase()
    //                 .includes("warning")
    //                 ? "red"
    //                 : "green",
    //             }}>{`${row.sensor[0].reading}`}</span>
    //         );
    //       }
    //     } else {
    //       return "N/A";
    //     }
    //   },
    // },
    
  ];

  for (let i = 1; i <= 8; i++) {
    columns.push({
      dataField: `additional_info[${i - 1}].outlet_label`,
      text: `Outlet ${i}`,
      sort: true,
      formatter: (cell, row) => {
        if (row.additional_info && row.additional_info[i - 1]) {
          return (
            <Button
              variant={
                row.additional_info[i - 1].power_state.toLowerCase() === "off"
                  ? "danger"
                  : "success"
              }
              onClick={() =>
                handlePduAction(
                  row.name,
                  row.pdu_ip,
                  row.additional_info[i - 1].outlet_number,
                  "cycle"
                )
              }>
              {row.additional_info[i - 1].outlet_label}
            </Button>
          );
        } else {
          return "N/A";
        }
      },
    });
  }
  

  if (
    user === "" ||
    user === undefined ||
    user === null ||
    token === "" ||
    token === undefined ||
    token === undefined
  ) {
    return <Redirect from="/PduManager" to="/Login?previousPage=PduManager" />;
  } else {
    return (
      <div style={{ width: "95%", textAlign: "center", margin: "0 auto" }}>
        <h1>PDU Manager</h1>
        {isLoading ? (
          <>
            <Spinner animation="border" role="status" /> Loading...
          </>
        ) : (
          <>
            <ToastContainer
              enableMultiContainer
              containerId={"A"}
              position="top-left"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ textAlign: "left" }}
            />
            <Tabs defaultActiveKey={d_tab}>
              <Tab eventKey="pduOverview" title="PDU Overview">
                <br />
                <div class="container">
                  <TextField
                    type="text"
                    placeholder="Filter by Site name..."
                    inputRef={keyword}
                    onChange={() => {
                      searchHandler(keyword.current.value);
                    }}
                    className="form-control w-100"
                  />
                </div>
                <br />
                <br />
                <BootstrapTable
                  keyField="id"
                  data={filteredData}
                  columns={columns}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
              <Tab eventKey="pduAction" title="PDU Scheduled Action">
                <br />
                <br />
                <BootstrapTable
                  keyField="id"
                  data={pduActionData}
                  columns={pduActionData_columns}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    );
  }
};
export default PduManager;
