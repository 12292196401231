import React, { useEffect, useState, useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import Axios from "axios";

function IsrSummaryComp(props) {
  const [isloading, setIsLoading] = useState(true);
  const [telstracommCount, setTelstracommCount] = useState(0);
  const [optuscommCount, setOptuscommCount] = useState(0);
  useEffect(() => {
    const getTelstracommCount = (data) => {
      let count = 0; //including KEPK, SSIM and DSIM
      data.forEach((c) => {
        if (
          c.status == "existing" &&
          c.host.name.toLowerCase() !== "ga" &&
          c.host.name.toLowerCase() !== "corsnet-nsw"
        ) {
          if (
            c.comms[0] !== undefined &&
            c.comms[0].name.toLowerCase().includes("telstra")
          ) {
            count++;
          }
        }
      });
      setTelstracommCount(count);
    };
    const getOptuscommCount = (data) => {
      // let count = 0;
      let count = 3; // there are 2 optus comm for testing (Lpoc, Opoc and DSIM)
      data.forEach((c) => {
        if (
          c.status == "existing" &&
          c.host.name.toLowerCase() !== "ga" &&
          c.host.name.toLowerCase() !== "corsnet-nsw"
        ) {
          if (
            c.comms[0] !== undefined &&
            c.comms[0].name.toLowerCase().includes("optus")
          ) {
            count++;
          }
        }
      });
      setOptuscommCount(count);
    };

    const fetchGpsMongoData = async () => {
      try {
        const res = await Axios.get("https://cms.vicpos.com.au/cors?_limit=-1");
        if (res.data.length > 0) {
          getTelstracommCount(res.data);
          getOptuscommCount(res.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchGpsMongoData();
  }, []);
  return (
    <div>
      {isloading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <>
          <br />
          <Button variant="primary" disabled="true" style={{ opacity: "100" }}>
            Telstra{" "}
            <span className="badge badge-danger">
              {props.telstraCommCount} / {telstracommCount}
            </span>
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;
          <Button variant="primary" disabled="true" style={{ opacity: "100" }}>
            Optus{" "}
            <span className="badge badge-danger">
              {props.optusCommCount} / {optuscommCount}
            </span>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="primary" disabled="true" style={{ opacity: "100" }}>
            Unknown{" "}
            <span className="badge badge-danger">{props.unknowCommCount}</span>
          </Button>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default IsrSummaryComp;
