import React from "react";
import { Card } from "react-bootstrap";

function FileOverview({ data, title }) {
  return (
    <div>
      <Card
        style={{
          width: "200px",
          float: "left",
          marginRight: "10px",
          marginBottom: "10px",
          padding: "10px",
        }}
      >
        <Card.Title>{title}</Card.Title>
        <Card.Body>
          <h3
            style={{
              color: data >= 95 ? "green" : data >= 80 ? "#067BC2" : "red",
            }}
          >
            {Math.round(data * 100) / 100} %
          </h3>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FileOverview;
