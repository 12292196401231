import Axios from "axios";
import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Paper, Tooltip } from "@material-ui/core";
import { Spinner, Jumbotron, Form } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import { CloudDownloadIcon, FireIcon } from "@heroicons/react/solid/";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import { UserContext } from "../contexts/UserContext";

import S3AccessComponent from "./S3AccessComponent";
import { handleMultipleFileDownload } from "./MultipleFileDL";
import handleFileDownload from "./S3FileDownloader";
import { Redirect } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
// import { selection } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { v4 as uuidv4 } from "uuid";
// import { MapComponent, withLeaflet } from "react-leaflet";
import moment from "moment";

function aws_test_lambda() {
  const [formData, setFormData] = useState({
    firstObs: "",
    lastObs: "",
    cors: "",
    intv: "",
    projName: "",
    email: "",
  });

  const [corsDropdownLoading, setCorsDropdownLoading] = useState(true);
  const [availableCors, setAvailableCors] = useState([]);
  const [selectedCors, setSelectedCors] = useState([]);
  const [firstObsTime, setFirstObsTime] = useState(
    moment().utc().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [lastObsTime, setLastObsTime] = useState(
    moment().utc().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [cors, setCors] = useState([]);
  const user = localStorage.getItem("username");

  useEffect(() => {
    // populate Station list for autocomplete component
    const fetchCorsCode = async () => {
      await Axios.get("https://cms.vicpos.com.au/cors?_limit=-1").then(
        (res) => {
          let codes = [];
          res.data.forEach((element) => {
            if (
              element.status.toLowerCase() === "existing" ||
              element.status.toLowerCase() === "decomissioned"
            )
              codes.push(element.code);
          });
          setAvailableCors(codes);
          setCorsDropdownLoading(false);
        }
      );
    };
    fetchCorsCode(); // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const notifyDataInfo = (message) =>
    toast.success(
      <div>{message}</div>,

      {
        position: toast.POSITION.TOP_RIGHT,
        containerId: "A",
      }
    );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.firstObs = firstObsTime;
      formData.lastObs = lastObsTime;
      formData.cors = cors;
      const response = await fetch(
        "https://s3api.vicpos.com.au/test_aws_lambda",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setSelectedCors([]);
        setFormData({
          firstObs: "",
          lastObs: "",
          cors: "",
          intv: "",
          projName: "",
          email: formData.email,
        });
        notifyDataInfo("Project " + formData.projName + " submitted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (user === "" || user === undefined || user === null) {
    return (
      <Redirect from="/AwsTestLambda" to="/Login?previousPage=AwsTestLambda" />
    );
  } else {
    return (
      <div style={{ width: "90%", textAlign: "center", margin: "0 auto" }}>
        <ToastContainer
          enableMultiContainer
          containerId={"A"}
          position="top-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ textAlign: "left" }}
        />
        <br />
        <Form onSubmit={handleSubmit}>
          <div>
            <TextField
              style={{ marginRight: "10px" }}
              id="date-picker"
              label="First Observation"
              type="datetime-local"
              defaultValue={firstObsTime}
              onChange={(e) => {
                setFirstObsTime(e.target.value);
              }}
              required
            />
            <TextField
              style={{ marginRight: "10px" }}
              id="date-picker"
              label="Last Observation"
              type="datetime-local"
              defaultValue={lastObsTime}
              onChange={(e) => {
                setLastObsTime(e.target.value);
              }}
              required="true"
            />
          </div>
          <br />
          <div>
            {corsDropdownLoading ? (
              <Spinner animation="border" />
            ) : (
              <Autocomplete
                multiple
                id="cors"
                options={availableCors}
                className="w-100 p-3"
                value={selectedCors}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Station Code(s)"
                    placeholder="CORS"
                    size="medium"
                  />
                )}
                style={{ Width: "100%", scrollPadding: "10px" }}
                onChange={(e, newValue) => {
                  setSelectedCors(newValue);
                  setCors(newValue);
                }}
              />
            )}
          </div>
          <br />
          <Form.Group controlId="intv">
            <TextField
              label="Interval"
              variant="outlined"
              type="number"
              name="intv"
              value={formData.intv}
              onChange={handleChange}
              min="1"
              required
              fullWidth
            />
          </Form.Group>
          <div>
            <Form.Group controlId="projName">
              <TextField
                label="Project Name"
                variant="outlined"
                type="text"
                name="projName"
                value={formData.projName}
                onChange={handleChange}
                required
                fullWidth
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="email">
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Form.Group>
          </div>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
export default aws_test_lambda;
