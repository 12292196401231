import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();
const UserContextProvider = (props) => {
  // eslint-disable-next-line

  const [user, setUser] = useState({
    token: "",
    username: "",
    email: "",
  });

  const fetchLocalUser = () => {
    if (localStorage.getItem("username") !== "") {
      setUser({
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        email: localStorage.getItem("email"),
      });
    }
  };

  useEffect(() => {
    fetchLocalUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
