import React, { useContext } from "react";
import DataFetching from "./DataFetching";
import { Card } from "react-bootstrap";
import SiteDetails from "./SiteDetails";
import { ApiContext } from "../contexts/ApiContext";

function Status() {
  const currURL = window.location.href;
  const len = currURL.length;
  const first = len - 4;
  const station = currURL.slice(first, len);
  //const { currStation } = useContext(CorsContext);
  //let stationSelected = currStation === "" ? station : currStation;
  const { apis } = useContext(ApiContext);
  const gpsnetServer = apis.gpsnetAzureSydAPI;
  const gaServer = apis.gaAPI + "findByFourCharacterId?id=";
  const t02API = gpsnetServer + "/t02/" + station;
  const rinexAPI = gpsnetServer + "/rinex/" + station;
  const avgstats = gpsnetServer + "/sats/" + station;
  const gaAPI = gaServer + station;

  var date = new Date();
  let test_time = new Date(date).toISOString();
  return (
    <div>
      <br />
      <h2>Time Zone UTC</h2>
      <p>UTC time: {test_time}</p>

      <br />
      <Card>
        <Card.Header>
          <h2>T02 file completeness of {station} for the last 30 days</h2>
        </Card.Header>
        <Card.Body>
          <DataFetching
            api={t02API}
            fillcolor="#DC7633"
            stroke="#D35400"
            unit="%"
            name="Completeness"
          />
        </Card.Body>
        <Card.Footer>
          <p>Hourly File</p>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Header>
          <h2>Rinex file completeness of {station} for the last 30 days</h2>
        </Card.Header>
        <Card.Body>
          <DataFetching
            api={rinexAPI}
            fillcolor="#5DADE2"
            stroke="#1A5276"
            unit="%"
            name="Completeness"
          />
        </Card.Body>
        <Card.Footer>
          <p>Daily File</p>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Header>
          <h2>Average Sats of {station} for the last 30 days</h2>
        </Card.Header>
        <Card.Body>
          <DataFetching
            api={avgstats}
            fillcolor="#A569BD"
            stroke="#6C3483"
            unit=" "
            name="Stats"
          />
        </Card.Body>
        <Card.Footer>
          <p>Avg Sats hourly</p>
        </Card.Footer>
      </Card>
      <SiteDetails api={gaAPI} station={station} />
    </div>
  );
}

export default Status;
