import React from "react";
import AWS from "aws-sdk";
import awsConfig from "./aws-config";

const generatePresignedUrl = async (fileKey) => {
  AWS.config.update({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region,
  });

  const s3 = new AWS.S3();

  const params = {
    Bucket: awsConfig.bucketName,
    Key: fileKey,
    Expires: 60, // URL expires in 60 seconds (adjust as per your needs)
    ResponseContentDisposition: `attachment; filename="${fileKey
      .split("/")
      .pop()}"`,
  };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};

const handleMultipleFileDownload = async (fileKeys) => {
  try {
    const downloadPromises = fileKeys.map(async (fileKey) => {
      const url = await generatePresignedUrl(fileKey);
      const response = await fetch(url);
      const fileContent = await response.arrayBuffer();
      const fileName = fileKey.substring(fileKey.lastIndexOf("/") + 1);
      const file = new Blob([fileContent], {
        type: "application/octet-stream",
      });
      const fileUrl = URL.createObjectURL(file);

      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      link.click();

      // Clean up
      URL.revokeObjectURL(fileUrl);
    });

    await Promise.all(downloadPromises);
  } catch (error) {
    console.error(
      "Error generating pre-signed URLs or downloading files:",
      error
    );
  }
};

const MultipleFileDL = ({ fileKeys }) => {
  return (
    <div>
      <button onClick={() => handleMultipleFileDownload(fileKeys)}>
        Download Files
      </button>
    </div>
  );
};

export { MultipleFileDL, handleMultipleFileDownload };
