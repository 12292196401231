import axios from "axios";
import { ApiContext } from "../contexts/ApiContext";
import React, { useEffect, useState, useContext } from "react";
import { Spinner, Tabs, Tab, Badge } from "react-bootstrap";
import Posts from "./Posts";
import CustomPagination from "./Pagination";
import AnnualReport from "./AnnualReport";

function Home() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const { apis } = useContext(ApiContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  // const [currentOutagePosts, setCurrentOutagePosts] = useState([]);

  useEffect(() => {
    const fetchPost = async () => {
      // eslint-disable-next-line
      const data = await axios
        .get(apis.gpsCMS_API + "/posts?_sort=start_date:desc")
        .then((res) => {
          setPosts(res.data);
          setLoading(false);
        });
    };

    fetchPost();
    setLoading(loading); // eslint-disable-next-line
  }, []);

  let currentResolvedPost = posts.filter((p) =>
    p.title.toLowerCase().includes("resolve")
  );

  let currentOutagePost = posts.filter(
    (p) =>
      !p.title.toLowerCase().includes("resolve") &&
      p.title.toLowerCase().includes("schedule")
  );

  let currentInfoPost = posts.filter(
    (p) =>
      !p.title.toLowerCase().includes("resolve") &&
      !p.title.toLowerCase().includes("schedule")
  );

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = (posts) =>
    posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1>GPSnet Web Application</h1>

      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="primary" /> Loading...
        </div>
      ) : posts.length === 0 ? (
        <h3>no coming work</h3>
      ) : (
        <div>
          <Tabs defaultActiveKey="info">
            <Tab
              eventKey="info"
              title={
                <>
                  Information{" "}
                  <Badge variant="danger">{currentInfoPost.length}</Badge>
                </>
              }
            >
              <CustomPagination
                postsPerPage={postsPerPage}
                totalPosts={currentInfoPost.length}
                paginate={paginate}
              />
              <Posts
                posts={currentPosts(currentInfoPost)}
                loading={loading}
                pageNumber={currentPage}
              />
            </Tab>
            <Tab
              eventKey="outage"
              title={
                <>
                  Outage{" "}
                  <Badge variant="danger">{currentOutagePost.length}</Badge>
                </>
              }
            >
              <CustomPagination
                postsPerPage={postsPerPage}
                totalPosts={currentOutagePost.length}
                paginate={paginate}
              />
              <Posts
                posts={currentPosts(currentOutagePost)}
                loading={loading}
                pageNumber={currentPage}
              />
            </Tab>
            <Tab
              eventKey="resolved"
              title={
                <>
                  Resolved{" "}
                  <Badge variant="danger">{currentResolvedPost.length}</Badge>
                </>
              }
            >
              <CustomPagination
                postsPerPage={postsPerPage}
                totalPosts={currentResolvedPost.length}
                paginate={paginate}
              />
              <Posts
                posts={currentPosts(currentResolvedPost)}
                loading={loading}
                pageNumber={currentPage}
              />
            </Tab>
            <Tab eventKey="annualReport"
              title={
                <>
                  Annual Report{" "}<Badge variant="danger">4</Badge>
                </> }>
                  <AnnualReport />
              </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default Home;
