import React from 'react'
import { Button, Table } from 'react-bootstrap'

function AnnualReport() {
  return (
    <div class="container">
    <h1>Annual Report</h1>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Financial Year</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2020-21 GPSnet works summary</td>
          <td><Button target='_blank' href={process.env.PUBLIC_URL + '/2020-21_GPSnet_Works_Summary.pdf'}>View</Button></td>
        </tr>
        <tr>
          <td>2021-22 GPSnet works summary</td>
          <td><Button target='_blank' href={process.env.PUBLIC_URL + '/2021-22_GPSnet_Works_Summary.pdf'}>View</Button></td>
        </tr>
        <tr>
          <td>2022-23 GPSnet works summary</td>
          <td><Button target='_blank' href={process.env.PUBLIC_URL + '/2022-23_GPSnet_Works_Summary.pdf'}>View</Button></td>
        </tr>
        <tr>
          <td>2023-24 GPSnet works summary</td>
          <td><Button target='_blank' href={process.env.PUBLIC_URL + '/2023-24_GPSnet_Works_Summary.pdf'}>View</Button></td>
        </tr>
        </tbody>
        </Table>
    {/* <div>
        <Button target='_blank' href={process.env.PUBLIC_URL + '/2021-22_GPSnet_Works_Summary.pdf'}>2021-22 GPSnet works summary</Button>
    </div>
    <div>
        <Button target='_blank' href={process.env.PUBLIC_URL + '/2022-23_GPSnet_Works_Summary.pdf'}>2022-23 GPSnet works summary</Button>
    </div>
    <div>
        <Button target='_blank' href={process.env.PUBLIC_URL + '/2023-24_GPSnet_Works_Summary.pdf'}>2023-24 GPSnet works summary</Button>
    </div> */}
    </div>
  )
}

export default AnnualReport