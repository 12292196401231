import React, { useEffect, useState } from "react";
import Axios from "axios";
import * as ReactBootStrap from "react-bootstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const DataFetching = ({ api, fillcolor, stroke, unit, name }) => {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      // eslint-disable-next-line
      const data = await Axios.get(api).then((res) => {
        setFileData(res.data);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading ? (
        <ReactBootStrap.Spinner animation="border" />
      ) : (
        <ResponsiveContainer width="98%" height={300}>
          <AreaChart
            data={fileData}
            isAnimationActive={true}
            margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={false} />
            <YAxis unit={unit} />
            <CartesianGrid strokeDasharray="3 3" />
            <Area
              type="monotone"
              dataKey="data"
              stroke={stroke}
              fill={fillcolor}
              animationDuration={1500}
              isAnimationActive={true}
              animationBegin={0}
              animationEasing="linear"
              name={name}
            />
            <Tooltip />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default DataFetching;
