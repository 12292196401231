import React, { createContext, useContext, useState, useEffect } from "react";
import Axios from "axios";
import { ApiContext } from "./ApiContext";

export const CorsAzureMelTestContext = createContext();

const CorsAzureMelTestContextProvider = (props) => {
  const { apis } = useContext(ApiContext);
  const [corsData, setCorsdata] = useState([]);
  const [filteredCorsData, setFilteredCorsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [over95RCheck, setOver95RCheck] = useState(false);
  const [over95TCheck, setOver95TCheck] = useState(false);
  const [under95RCheck, setUnder95RCheck] = useState(false);
  const [under95TCheck, setUnder95TCheck] = useState(false);
  useEffect(() => {
    fetchData(); // eslint-disable-next-line
    setLoading(false); // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    // eslint-disable-next-line
    const corsdata = await Axios.get(apis.gpsnetAzureMelAPI + "/test_cors")
      .then((res) => {
        setCorsdata(res.data);
        setFilteredCorsData(res.data);
      })
      .catch((e) => console.log(e));
  };
  return (
    <CorsAzureMelTestContext.Provider
      value={{
        corsData,
        loading,
        filteredCorsData,
        setFilteredCorsData,
        over95RCheck,
        over95TCheck,
        under95RCheck,
        under95TCheck,
        setOver95RCheck,
        setOver95TCheck,
        setUnder95RCheck,
        setUnder95TCheck,
      }}
    >
      {props.children}
    </CorsAzureMelTestContext.Provider>
  );
};

export default CorsAzureMelTestContextProvider;
